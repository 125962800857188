import type { ProxyTarget, TargetStore } from "./store.types.js";
import * as symbols from "../utils/symbols.js";
import type { AnyFunction } from "../utils/utils.types.js";
import { getStore, nexusScope, stores } from "./utils.js";
import { setupEventProxies } from "./store.events.js";

const visiblePropDescriptor: PropertyDescriptor = {
  enumerable: true,
  configurable: true,
};
const noopDescriptor = { ...visiblePropDescriptor, value: symbols.noop };

export const eventMembers = ["on", "receive", "subscribe", "sub", "emit", "dispatch"] as const;
export const assignMembers = ["assignEvent", "assignNamespace", "assignFunction"] as const;
export const namespaceMembers = ["then", "with", "toString"] as const;

const reservedMembers = [...eventMembers, ...assignMembers, ...namespaceMembers] as const;

/**
 *
 *
 */
export function setupReady(store: TargetStore): void {
  delete store.isReady;

  store.ready = new Promise<AnyFunction>((resolve) => {
    store.onReady = resolve;
  });
}

export function setupTargetProps(target: ProxyTarget, ...props: string[]): void {
  props.forEach((key) => {
    Object.defineProperty(target, key, noopDescriptor);
  });
}

export function removeTargetProps(target: ProxyTarget, ...props: string[]): void {
  props.forEach((key) => {
    delete target[key];
  });
}

/**
 *
 *
 *
 *
 */
export function createStore(
  name: string | symbol,
  path: string = name.toString(),
): [store: TargetStore, target: ProxyTarget] {
  /*                                    */
  const store = {
    invocations: [],
    listeners: [],
    members: {},
    path,
    log: nexusScope.scope(path),
  } as unknown as TargetStore;

  /*                 */
  setupReady(store);

  /*                                                            */
  /*                                       */
  const target = function () {
    /* */
  } as ProxyTarget;

  /*                                              */
  Object.defineProperty(target, "name", { value: name });

  (
    [
      ["path", () => store.path],
      ["isSet", () => !!store.handler],
      ["handler", () => store.handler ?? null],
      ["isReady", () => !!store.isReady],
      ["isNs", () => !!store.isNs],
      ["isFunc", () => !!store.isFunc],
      ["isEvent", () => !!store.isEvent],
      ["isSig", () => !!store.isSig],
    ] as const
  ).forEach(([key, getter]) => {
    Object.defineProperty(target, key, { ...visiblePropDescriptor, get: getter });
  });

  /*                                                */
  setupTargetProps(target, ...reservedMembers);

  /*                */
  stores.set(target, store);
  return [store, target];
}

/**
 *
 *
 *
 *
 *
 *
 */
export function cleanupStore(store: TargetStore, target: ProxyTarget, isNamespace = false): void {
  delete store.handler;
  delete store.isNs;
  delete store.isFunc;
  delete store.isEvent;
  delete store.isSig;
  delete store.retention;
  delete store.retained;
  delete store.detail;

  if (isNamespace) {
    /*                                                 */
    store.listeners.splice(0);
    store.invocations.splice(0);
    return;
  }

  /*                                                               */
  Object.keys(store.members).forEach((key) => {
    delete store.members[key];
    delete target[key];
  });
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function ensurePropStore(
  target: ProxyTarget,
  p: string | symbol,
): [propStore: TargetStore, propTarget: ProxyTarget] {
  const store = getStore(target);

  if (!store.members[p]) {
    const [propStore, propTarget] = createStore(p, `${store.path}.${p.toString()}`);
    store.members[p] = propTarget;

    /*                                          */
    Object.defineProperty(target, p, {
      ...visiblePropDescriptor,
      writable: false,
      value: store.members[p],
    });

    /*                                                      */
    /*                             */
    if (store.eventTarget) {
      setupEventProxies(store, propStore);
    }
  }

  const propTarget = store.members[p];
  const propStore = getStore(propTarget);

  return [propStore, propTarget];
}
