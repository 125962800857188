import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { getHeadElement } from "../dom/head";

/**
 *
 *
 *
 *
 *
 */
function injectScript(source: HTMLScriptElement, target: HTMLScriptElement): HTMLScriptElement {
  /*                                                                         */
  if (source.parentNode && (source.closest("body") || source.closest("head"))) {
    source.parentNode.replaceChild(target, source);
    return target;
  }

  /*                                           */
  const head = getHeadElement();
  /*                                              */
  head.insertBefore(target, head.firstChild);
  /*                                                        */
  head.removeChild(target);

  return target;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function evalScript(source: HTMLScriptElement): HTMLScriptElement {
  /*                                                     */
  const target = document.createElement("script");

  /*                                            */
  Array.from(source.attributes).forEach((attribute) => {
    target.setAttribute(attribute.name, attribute.value);
  });

  /*                                                   */
  if (source.src) {
    return injectScript(source, target);
  }

  /*                                              */
  if (source.getAttribute("type") === "module") {
    target.text = source.text;
    return injectScript(source, target);
  }

  /*                                       */
  /*                   */
  const data = source.text || source.textContent || source.innerHTML || "";
  target.type = "text/javascript";
  target.text = data;
  return injectScript(source, target);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function executeInlineScripts(element: HTMLElement): HTMLScriptElement[] {
  const scripts: HTMLScriptElement[] = [];
  const ret = element.getElementsByTagName("script");

  /*                                                                          */
  for (let i = 0; ret[i]; i += 1) {
    if (
      !ret[i].type ||
      ret[i].type.toLowerCase() === "text/javascript" ||
      ret[i].type === "module"
    ) {
      scripts.push(ret[i]);
    }
  }

  /*                           */
  return scripts.map(evalScript);
}

assignNamespace(o_util, "hardcore", {
  evalScript,
  executeInlineScripts,
});
