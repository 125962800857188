import { get } from "../cookie/get.js";
import { set } from "../cookie/set.js";
import { remove } from "../cookie/remove.js";
import type { DebugConfig, LogLevelInput, ConfigInput } from "./status.js";

/*                                              */
const debugCookie = "debug";
/*                                           */
const debugConfig = "debugConfig";
/*                      */
const separator = ";%;";
/*                                         */
const stringTrue = "true";

/**
 *
 *
 *
 */
export function writeCookie(config: DebugConfig): DebugConfig {
  const stringified = [config.pattern?.source, config.rule + config.level, config.colors].join(
    separator,
  );

  set(debugConfig, stringified);

  return config;
}

/**
 *
 */
export function removeCookie(): void {
  [debugCookie, debugConfig].forEach((c) => remove(c));
}

/**
 *
 *
 *
 */
export function readCookies(): ConfigInput {
  const debug = get(debugCookie);
  const [
    pattern = import.meta.env.VITE_APP_DEBUG_PATTERN || undefined,
    level = import.meta.env.VITE_APP_DEBUG_LEVEL,
    colors = stringTrue,
  ] = get(debugConfig)?.split(separator) ?? [];

  return {
    pattern: pattern ? new RegExp(pattern) : undefined,
    level: (level as LogLevelInput) ?? undefined,
    colors: colors === stringTrue,
    activated: debug === stringTrue,
  };
}

/**
 *
 */
export function legacyCookie(): void {
  set(debugCookie, stringTrue);
}
