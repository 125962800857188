/*                                                          */
export type AnyFunction = (...args: any[]) => unknown;

/**
 *
 */
export const delegationStore: {
  /*                            */
  container: HTMLElement | Document;
  /*                                   */
  observer: MutationObserver;
  /*                                      */
  delegations: {
    targetSelector: string;
    eventType: keyof HTMLElementEventMap;
    listener: AnyFunction;
    options?: boolean | AddEventListenerOptions;
  }[];
}[] = [];

/**
 *
 *
 *
 */
export type DelegationConfig = (typeof delegationStore)[number];
export type Delegation = DelegationConfig["delegations"][number];

/**
 *
 *
 *
 */
export function isEventable(element: unknown): element is HTMLElement {
  return (
    typeof element === "object" && typeof (element as HTMLElement).addEventListener === "function"
  );
}

/**
 *
 *
 *
 */
export function isQueryable(
  element: Element | Document | string | null,
): element is HTMLElement | Document {
  return !!element && typeof element === "object" && typeof element.querySelectorAll === "function";
}

/**
 *
 *
 */
export function addEvent(params: {
  /*                            */
  target: Element | Document;
  eventType: keyof HTMLElementEventMap;
  listener: AnyFunction;
  options?: boolean | AddEventListenerOptions;
}): void {
  params.target.addEventListener(params.eventType, params.listener, params.options);
}

/**
 *
 *
 *
 *
 *
 *
 */
function getMutationCallback(config: DelegationConfig) {
  return (mutations: MutationRecord[]) => {
    /*                                                                 */
    /*                                         */
    mutations.forEach((m) =>
      Array.from(m.addedNodes)
        /*                                  */
        .filter((e): e is Element => e instanceof Element && typeof e.matches === "function")
        /*                                          */
        .forEach((e) =>
          config.delegations
            /*                         */
            .forEach((d) => {
              /*                                                 */
              /*                              */
              const els = Array.from(e.querySelectorAll(d.targetSelector));

              /*                                                */
              /*                                                 */
              if (isEventable(e) && e.matches(d.targetSelector)) {
                els.push(e);
              }

              /*                                            */
              els.forEach((target) => addEvent({ ...d, target }));
            }),
        ),
    );
  };
}

/**
 *
 *
 *
 *
 *
 */
export function getContainerElement(
  containerOrSelector: string | HTMLElement | Document,
): HTMLElement | Document | null {
  /*                                         */
  return containerOrSelector === "document"
    ? document
    : /*                                         */
      isQueryable(containerOrSelector)
      ? containerOrSelector
      : typeof containerOrSelector === "string"
        ? document.querySelector<HTMLElement>(containerOrSelector)
        : null;
}

/**
 *
 *
 *
 *
 *
 */
export function queryDelegationTargets(
  container: HTMLElement | Document,
  targetSelector: string,
): HTMLElement[] {
  return Array.from(container.querySelectorAll(targetSelector)).filter(isEventable);
}

/**
 *
 *
 *
 *
 */
export function findDelegationConfig(
  container: HTMLElement | Document,
): DelegationConfig | undefined {
  return delegationStore.find((c) => c.container === container);
}

/**
 *
 *
 *
 *
 */
export function addDelegationConfig(container: HTMLElement | Document): DelegationConfig {
  /*                                  */
  const config: DelegationConfig = { container, delegations: [], observer: undefined as never };
  /*                       */
  config.observer = new MutationObserver(getMutationCallback(config));
  config.observer.observe(container, { subtree: true, childList: true });
  delegationStore.push(config);

  return config;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function findDelegation(
  config: DelegationConfig,
  eventType: keyof HTMLElementEventMap,
  targetSelector: string,
  listener: AnyFunction,
): Delegation | undefined {
  const delegation = config.delegations.find(
    (d) =>
      d.listener === listener && d.eventType === eventType && d.targetSelector === targetSelector,
  );
  return delegation;
}
