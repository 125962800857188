import { logLevels, type LogLevels, levelRules, type RulesTypes, defaultLevel } from "./level.js";

export type StatusText = "activated" | "deactivated";

export interface DebugConfig {
  /**
 *
 */
  enabled: boolean;
  /**
 *
 *
 */
  pattern: RegExp | undefined;
  /**
 *
 */
  level: LogLevels;
  /**
 *
 */
  rule: RulesTypes;
  /**
 *
 */
  colors: boolean;
  /**
 *
 */
  activated: boolean;
  /**
 *
 */
  text: StatusText;
}

const debugConfig: DebugConfig = {
  pattern: undefined,
  level: defaultLevel,
  rule: ">=",
  enabled: false,
  activated: false,
  text: "deactivated",
  colors: true,
};

/**
 *
 */
export type LogLevelInput = LogLevels | `${RulesTypes}${LogLevels}`;

/**
 *
 *
 *
 *
 *
 *
 */
export function status(): Readonly<DebugConfig> {
  /*                                  */
  return { ...debugConfig };
}

export type ConfigInput = {
  pattern?: RegExp | null;
  level?: LogLevelInput;
  activated?: boolean;
  colors?: boolean;
};

/**
 *
 *
 *
 *
 *
 *
 */
export function updateStatus(params: ConfigInput): DebugConfig {
  Object.entries(params).forEach((e) => {
    if (e[1] !== undefined) {
      /*                                                                 */
      debugConfig[e[0]] = e[1] ?? undefined;
    }
  });

  /*                             */
  debugConfig.enabled = !!debugConfig.pattern;
  debugConfig.text = debugConfig.activated ? "activated" : "deactivated";

  if (!debugConfig.enabled) {
    debugConfig.level ??= defaultLevel;
    return status();
  }

  /*                         */
  if (!("level" in params)) {
    return status();
  }

  /*                                    */
  let [, rule, level] = debugConfig.level.match(/^([<>=!]+)?(\w+)$/)! as [
    never,
    RulesTypes,
    LogLevels,
  ];

  debugConfig.rule = levelRules[rule] ? rule : ">=";
  debugConfig.level = logLevels.includes(level) ? level : defaultLevel;

  /*                                  */
  return status();
}
