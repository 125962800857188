/*                                       */
import { type ProxyConfig, getProxyConfig, proxies, reservedKeys } from "./proxyConfig.js";
import { ensurePropStore } from "../store/store.js";
import { applyHandler } from "./getters.js";
import * as symbols from "../utils/symbols.js";
import { AnyFunction } from "../utils/utils.types.js";
import { ProxyTarget } from "../store/store.types.js";
/*                                       */
import { assignStoreValue } from "../store/assign.js";
import { getStore } from "../store/utils.js";

function tryGetProp(val: unknown | undefined, p: string | symbol): unknown | undefined {
  const v = val as { [k: string | symbol]: unknown } | undefined;

  if (v && p in v && v[p] !== symbols.noop) {
    return v[p];
  }

  return undefined;
}

const proxyHandler: ProxyHandler<ProxyTarget> = {
  get(target, p): unknown {
    const store = getStore(target);

    /*                                     */
    if (!store) {
      /*                                                     */
      return undefined;
    }

    /*                                                       */
    const config = getProxyConfig(this);

    /*                           */
    if (store.members[p] && !reservedKeys.includes(p)) {
      /*                                                               */
      return createProxy(ensurePropStore(target, p)[1], config);
    }

    /*                                                             */
    /*                  */
    const getter = config[0][p];
    if (getter && getter !== symbols.noop) {
      return getter(store, target, config);
    }

    /*                                                               */
    /*                                                             */
    if (getter === symbols.noop) {
      return undefined;
    }

    /*                                                 */
    /*                                                      */
    const proVal = tryGetProp(store.handler, p) ?? tryGetProp(target, p);
    if (proVal !== undefined) {
      return proVal;
    }

    /*                                                       */
    /*                                                     */
    store.isNs = true;
    /*                                                               */
    return createProxy(ensurePropStore(target, p)[1], config);
  },

  set(target, p, newValue) {
    const store = ensurePropStore(target, p);
    assignStoreValue(...store, newValue);
    return true;
  },

  ownKeys(target) {
    const [getters] = getProxyConfig(this);
    const store = getStore(target);

    return Array.from(
      new Set([
        "prototype",
        ...Object.keys(getters).filter((key) => !!getters[key]),
        ...Object.keys(store.members),
        ...(store.handler
          ? Object.getOwnPropertyNames(store.handler)
          : Object.getOwnPropertyNames(target)),
      ]).values(),
    );
  },

  getOwnPropertyDescriptor(target, p) {
    return Reflect.getOwnPropertyDescriptor(target, p);
  },

  apply(target, thisArg, argArray) {
    const store = getStore(target);
    const config = getProxyConfig(this);
    return (applyHandler(store, target, config) as AnyFunction)(thisArg, argArray);
  },

  has(target, p) {
    return !!(this.ownKeys!(target) as (string | symbol)[]).includes(p);
  },
};

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function createProxy(target: ProxyTarget, config: ProxyConfig): ProxyTarget {
  const handler = { ...proxyHandler };
  proxies.set(handler, config);
  return new Proxy<ProxyTarget>(target, handler);
}
