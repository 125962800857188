/*                                       */
import { getStoreType } from "../store/utils.js";
import type { TargetStore } from "../store/store.types.js";
import { AssignOptions } from "../proxy/proxy.types.js";

const flags: (keyof TargetStore)[] = ["isNs", "isEvent", "isSig", "isFunc"];

function buildMessage(store: TargetStore, action: string): string {
  return "Can not ".concat(
    action,
    " ",
    store.path,
    " because is has been already initialized as ",
    getStoreType(store),
  );
}

/**
 *
 *
 *
 *
 *
 *
 */
export function assertNamespaceFree(
  store: TargetStore,
  action: string,
  options: AssignOptions = {},
): void {
  if (store.isReady) {
    throw Error(buildMessage(store, action));
  }

  store.isReady = (options.lock as never) ?? true;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function assertNamespaceType(
  store: TargetStore,
  flag: (typeof flags)[number],
  action: string,
): void {
  /*                        */
  if (store.isReady && flags.map((f) => f !== flag && store[f]).some(Boolean)) {
    throw Error(buildMessage(store, action));
  }

  /*                            */
  store[flag] = true as never;
}
