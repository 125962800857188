/**
 *
 */
export interface ListenerContainer {
  /**
 *
 */
  priority: number;
  /**
 *
 */
  fn: EventListener;
}

/**
 *
 */
export const store = {
  onReadyQueue: [] as ListenerContainer[],
  onReadyEvent: undefined as undefined | Event,
  get onReadyFired(): boolean {
    return !!store.onReadyEvent;
  },

  onLoadQueue: [] as ListenerContainer[],
  onLoadEvent: undefined as undefined | Event,
  get onLoadFired(): boolean {
    return !!store.onLoadEvent;
  },

  allPreloadScriptsLoadedQueue: [] as ListenerContainer[],
  allPreloadScriptsLoadedEvent: undefined as undefined | Event,
  get allPreloadScriptsLoadedFired(): boolean {
    return !!store.allPreloadScriptsLoadedEvent;
  },

  allJavascriptLoadedQueue: [] as ListenerContainer[],
  allJavascriptLoadedComplete: undefined as boolean | undefined,
  allJavascriptLoadedEvent: undefined as unknown,

  get allJavascriptLoadedFired(): boolean {
    return !!store.allJavascriptLoadedEvent;
  },
};
