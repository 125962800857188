export type EasingFunc = (t: number) => number;

/**
 *
 *
 */
export interface Easings {
  linear: EasingFunc;
  easeOutCubic: EasingFunc;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const easings = Object.freeze<Easings>({
  linear(t: number) {
    return t;
  },
  easeOutCubic(t: number) {
    /*                                                      */
    return --t * t * t + 1;
  },
});
