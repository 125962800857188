import { o_util } from "@gr-common/head/namespaces";
const core = o_util.core ?? {};
const coerce = core.coerce;
const convertStringToFunction = core.convertStringToFunction;
const deserialize = core.deserialize;
const extend = core.extend;
const isEmptyObject = core.isEmptyObject;
const isPlainObject = core.isPlainObject;
const removeFromArray = core.removeFromArray;
const serialize = core.serialize;
const serializeForm = core.serializeForm;
const toArray = core.toArray;
export {
  coerce,
  convertStringToFunction,
  core,
  deserialize,
  extend,
  isEmptyObject,
  isPlainObject,
  removeFromArray,
  serialize,
  serializeForm,
  toArray
};
