/**
 *
 */
export type LogLevels = "trace" | "debug" | "info" | "warn" | "error";

/**
 *
 */
/*                               */
export const logLevels = ["trace", "debug", "info", "warn", "error"] as const satisfies LogLevels[];

/**
 *
 */
export const defaultLevel = logLevels[4];

/*                      */
function createLevelEnum(): { [P in LogLevels]: P } {
  return Object.fromEntries(logLevels.map((k) => [k, k])) as never;
}

/**
 *
 */
export const Level = createLevelEnum();

/**
 *
 */
export const levelColors = [
  "slategray",
  "dodgerblue",
  "limegreen",
  "darkorange",
  "orangered",
] as const;

/**
 *
 */
export type RulesTypes = "<" | ">" | "<=" | ">=" | "=" | "!";
/**
 *
 */
export const levelRules = {
  "<": (a, b) => a < b,
  ">": (a, b) => a > b,
  "<=": (a, b) => a <= b,
  ">=": (a, b) => a >= b,
  "=": (a, b) => a === b,
  "!": (a, b) => a !== b,
} as Record<RulesTypes, (current: number, active: number) => boolean>;
