function dateNow() {
  return /*           */ new Date();
}
function getTime() {
  return dateNow().getTime();
}
function now() {
  return Date.now();
}
export {
  dateNow,
  getTime,
  now
};
