import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { get } from "../cookie/get";
import { logger } from "../debug/debug";

const log = logger("o_util.misc");

/**
 *
 */
export function getPagecluster(): string | null {
  return document.documentElement.getAttribute("data-pagecluster");
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function isResponsive(): boolean {
  log.warn("o_util.misc.isResponsive() is deprecated and will be removed in the future.");
  return get("responsive-preview") === "true";
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function isValidMouseButton(event: MouseEvent, key: number): boolean {
  if (typeof event?.which === "number") {
    return event.which === key;
  }

  return true;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function setVendorStyle(element: HTMLElement, property: string, value: string): void {
  /*                                                        */
  const prefixProperty = property.charAt(0).toUpperCase() + property.slice(1);
  /*                                                          */
  const { style } = element as any;

  style[`webkit${prefixProperty}`] = value;
  style[`Moz${prefixProperty}`] = value;
  style[`ms${prefixProperty}`] = value;
  style[`O${prefixProperty}`] = value;
  style[property] = value;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function guid(): string {
  /*                                                                            */
  let offset = -1;
  const rvs = crypto.getRandomValues(new Uint8Array(31));

  /*                                  */
  /*                                                      */
  /*                                                      */
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    /*                                                                */
    /*                                               */
    /*                                  */
    const rv = rvs[(offset += 1)] & 15;
    /*                                                                  */
    /*                                        */
    /*                                  */
    return (c === "x" ? rv : (rv & 0x3) | 0x8).toString(16);
  });
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function nanoid(size = 21): string {
  return crypto.getRandomValues(new Uint8Array(size)).reduce((id, byte) => {
    /*                                                        */
    /*                                                            */
    /*                                                           */
    /*                                                                 */
    /*                                                 */
    /*                                                     */
    byte &= 63;
    if (byte < 36) {
      /*       */
      /*                                         */
      id += byte.toString(36);
    } else if (byte < 62) {
      /*    */
      /*                                         */
      id += (byte - 26).toString(36).toUpperCase();
    } else if (byte > 62) {
      /*                                         */
      id += "-";
    } else {
      /*                                         */
      id += "_";
    }
    return id;
  }, "");
}

/**
 *
 *
 *
 *
 *
 *
 */
export function urlToLinkObject(urlString: string): HTMLAnchorElement {
  const anchorElement = document.createElement("a");

  anchorElement.href = urlString;

  return anchorElement;
}

/**
 *
 *
 *
 */
export function isPreview(urlString: string): boolean {
  if (typeof urlString !== "string") {
    log.warn("o_util.misc.isPreview() parameter is no string");
    return false;
  }

  return urlString.indexOf("/shoppages/preview") !== -1;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function isPrivacyPage(): boolean {
  return window.location.pathname.includes("/shoppages/service/datenschutz");
}

assignNamespace(o_util, "misc", {
  getPagecluster,
  guid,
  isPreview,
  isPrivacyPage,
  isResponsive,
  isValidMouseButton,
  nanoid,
  setVendorStyle,
  urlToLinkObject,
});
