import { noop } from "./function";

/**
 *
 */
const win: typeof window = import.meta.env.SSR ? (globalThis as never) : window;

export const autoNamespace = /*           */ new Proxy(noop, {
  get: () => autoNamespace,
});

if (import.meta.env.SSR) {
  /**
 *
 */
  win.AS ||= autoNamespace as never;
  win.o_global ||= autoNamespace as never;
  win.o_util ||= autoNamespace as never;
} else {
  /**
 *
 *
 *
 */
  /*                                                                 */
  win.AS ||= {} as never;
  win.o_global ||= {} as never;
  win.o_util ||= {} as never;
}

win.__toggles__ ||= {} as never;

/*                   */
export { win as window };
export const { AS, o_global, o_util, __toggles__ } = win;
