/**
 *
 *
 *
 *
 *
 */
export function getConnectionType(): number {
  const { performance: p } = window;
  return p && p.navigation && "type" in p.navigation ? p.navigation.type : 255;
}
