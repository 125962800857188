import { o_util, __toggles__, o_global } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { get as getCookie } from "../cookie/get.js";
import { COOKIE_PREFIX, cookieMap, normalize, normalizeLegacy } from "./normalize.js";

/**
 *
 *
 *
 *
 */
export type GetToggleResult<T> = [value: true, payload: T] | [value: false, payload: undefined];

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getToggle<T = never>(
  toggleName: string,
  defaultValue: boolean,
): GetToggleResult<T | undefined>;
export function getToggle<T = never>(
  toggleName: string,
  defaultValue: boolean,
  defaultPayload: T,
): GetToggleResult<T>;
export function getToggle<T = never>(
  toggleName: string,
  defaultValue = true,
  defaultPayload = undefined as T,
): GetToggleResult<T> {
  const rawToggle = __toggles__[toggleName];
  const rawLegacy = o_global.toggles?.[toggleName];

  /*                                     */
  /*                                                              */
  const toggle = rawToggle
    ? normalize(rawToggle, defaultValue, defaultPayload)
    : normalizeLegacy(rawLegacy, defaultValue, defaultPayload);

  if (!toggle[1]) {
    /*                   */
    return [toggle[0], toggle[2] as never];
  }

  /*                             */
  const localStatus = getCookie(COOKIE_PREFIX + toggleName) as keyof typeof cookieMap | undefined;

  /*                         */
  if (!localStatus || cookieMap[localStatus] === undefined) {
    /*                      */
    return [toggle[0], toggle[2] as never];
  }

  /*                    */
  return [!!cookieMap[localStatus], toggle[2] as never];
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function get(toggleName: string, defaultValue = false): boolean {
  const [res] = getToggle(toggleName, defaultValue, undefined);
  return res;
}

/*                    */
assignNamespace(o_util, "toggle", { get, getToggle });
