import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { deprecated, log } from "@gr-common/head/tools";
import { defineEvent } from "../nexus/event/public";

export function getFirstElementByTagName<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  /*                */ w = window,
): HTMLElementTagNameMap[K] {
  return w.document.getElementsByTagName(tagName)[0];
}

/**
 *
 *
 */
export function getHeadElement(w = window): HTMLHeadElement {
  return getFirstElementByTagName("head", w);
}

/**
 *
 *
 */
export function getBodyElement(w = window): HTMLBodyElement {
  return getFirstElementByTagName("body", w);
}

/**
 *
 *
 *
 *
 */
export function stringToDocumentFragment(htmlString: string): DocumentFragment {
  const template = document.createElement("template");

  if (typeof htmlString !== "string") {
    log.warn(
      "stringToDocumentFragment should be called with strings containing html only, but was called with",
      typeof htmlString,
    );

    return template.content;
  }

  template.innerHTML = htmlString.trim();
  return template.content;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function hasClassInParents(ele: Element, className: string): boolean {
  return !!ele.closest(`.${className}`);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getParentByClassName<T extends Element>(ele: Element, className: string): T | null {
  return ele.closest(`.${className}`);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                 */
export function getElementsByClassname<T extends Element>(
  parentElement: HTMLElement,
  className: string,
): HTMLCollectionOf<T> {
  deprecated(
    "o_util.dom.getElementsByClassname",
    "use native document.getElementsByClassName instead",
  );
  return parentElement.getElementsByClassName(className) as HTMLCollectionOf<T>;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function hasClass(element: Element, className: string): boolean {
  return element.classList.contains(className);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function addClass(ele: Element, cls: string): void {
  deprecated("o_util.dom.addClass", "use native element.classList.add instead");

  if (!hasClass(ele, cls)) {
    /*                                         */
    ele.className += ` ${cls}`;
  }
}

/**
 *
 *
 *
 *
 *
 *
 */
export function removeClass(ele: Element, cls: string): void {
  deprecated("o_util.dom.removeClass", "use native element.classList.remove instead");
  ele.classList.remove(cls);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function toggleClass(ele: Element, cls: string): void {
  deprecated("o_util.dom.toggleClass", "use native element.classList.toggle instead");
  ele.classList.toggle(cls);
}
/*                */

assignNamespace(o_util, "dom", {
  addClass,
  getBodyElement,
  getElementsByClassname,
  getFirstElementByTagName,
  getHeadElement,
  getParentByClassName,
  hasClass,
  hasClassInParents,
  removeClass,
  stringToDocumentFragment,
  toggleClass,
});

/*                                                 */
/*                                   */
otto.dom.assignNamespace(
  {
    onLoad: defineEvent(),
    onReady: defineEvent(),
  },
  { lock: import.meta.env.VITE_OTTO_IS_TESTING_BUNDLE !== "true" },
);
