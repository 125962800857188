import { deprecated } from "@gr-common/head/tools";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function toArray<T>(object: ArrayLike<T>): T[] {
  deprecated("o_util.core.toArray", "use native Array.from instead");

  const array = [];

  for (let i = object.length - 1; i >= 0; i -= 1) {
    array[i] = object[i];
  }

  return array;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function removeFromArray<T>(originalArray: T[], from: number, to?: number): T[] {
  const rest = originalArray.slice((to || from) + 1 || originalArray.length);

  /*                                         */
  originalArray.length = from < 0 ? originalArray.length + from : from;
  /*                                     */
  originalArray.push.apply(originalArray, rest);

  return originalArray;
}
