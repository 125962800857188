/*                                                         */
import { camelcasify } from "@otto-ec/assets-core-utils/string";
import { loggerScope, withCache } from "./utils.js";
import { DEFAULT_PARAMETER_OPTIONS, ParametersParserOptions } from "./options.js";

const log = loggerScope.scope("name-parser");

/**
 *
 *
 *
 *
 */
export const MODIFIERS = ["base64", "ref"] as const;

/**
 *
 *
 *
 *
 *
 */
export const DELIMITERS = ["-", ".", "."] as const;

/**
 *
 */
/*                                                                            */

/**
 *
 */
export const attributeNamePattern = new RegExp(
  /*                                           */
  /*                                                                                                                                             */
  `^(?:${DELIMITERS[0]}([\\w-]+))?(?:\\${DELIMITERS[1]}([\\w-]+)(?:\\${DELIMITERS[2]}(\\w+)?)?)?$`,
);

/**
 *
 *
 *
 *
 */
export type Modifier = (typeof MODIFIERS)[number];

/**
 *
 */
export const attributeNameMatchCache = new Map<string, ReturnType<typeof matchAttributeName>>();

/**
 *
 *
 *
 */
export const attributeNameParseCache = new Map<string, ReturnType<typeof parseAttributeName>>();

/**
 *
 *
 *
 *
 *
 */
export type AttributeMetaData = [
  attribute: string,
  paramName: string,
  modifier: Modifier | undefined,
];

/**
 *
 */
export type AttributeEventValue = [...AttributeMetaData, eventName: string];

/**
 *
 */
export type AttributeDefaultValue = [...AttributeMetaData, isDefault: true];

/**
 *
 *
 *
 *
 */
export function isUb64Attribute(attr: [...AttributeMetaData, unknown]): boolean {
  return attr[2] === MODIFIERS[0];
}

/**
 *
 *
 *
 *
 */
export function isRefAttribute(attr: [...AttributeMetaData, unknown]): boolean {
  return attr[2] === MODIFIERS[1];
}

/**
 *
 *
 */
export function isDefaultValueAttribute(
  attr: AttributeDefaultValue | AttributeEventValue,
): attr is AttributeDefaultValue {
  return attr[3] === true;
}

/**
 *
 *
 *
 *
 *
 */
export function isRelevantAttribute(
  attr: AttributeDefaultValue | AttributeEventValue | undefined,
  identifier: string,
): attr is AttributeDefaultValue | AttributeEventValue {
  return !!attr && (isDefaultValueAttribute(attr) || attr[3] === identifier);
}

/**
 *
 */
export type AttributeNameBase = `data-${string}-v${number}`;

/**
 *
 *
 *
 *
 *
 *
 */
export function renameParam(param: string, camelCase: boolean, rename?: string): string {
  if (rename) {
    return rename;
  }

  if (camelCase) {
    return camelcasify(param);
  }

  return param;
}

export function matchAttributeName(
  base: AttributeNameBase,
  attr: string,
): [identifier: string | undefined, param: string | undefined, modifier: string | undefined] {
  /**
 *
 *
 *
 *
 *
 *
 *
 */
  const attributeName = attr.replace(base, "");

  /*                                                   */
  let [, identifier, paramOrModifier, modifier] =
    attributeNamePattern.exec(attributeName) ?? ([] as (string | undefined)[]);

  /*                                                      */
  if (MODIFIERS.includes(paramOrModifier as Modifier)) {
    modifier = paramOrModifier;
    paramOrModifier = undefined;
  }

  return [identifier, paramOrModifier, modifier];
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function parseAttributeName(
  base: AttributeNameBase,
  attr: string,
  unknownParameterName: string,
  /*                                                          */
  paramOptions: ParametersParserOptions<any>,
): AttributeDefaultValue | AttributeEventValue | undefined {
  if (!attr.startsWith(base)) {
    log.trace("Skip not supported attribute", attr);
    return undefined;
  }

  const [identifier, paramName = unknownParameterName, modifier] =
    attributeNameMatchCache.get(attr) ??
    withCache(attributeNameMatchCache, attr, matchAttributeName(base, attr));

  const { rename, camelCase = DEFAULT_PARAMETER_OPTIONS.camelCase } = paramOptions[paramName] ?? {};

  /**
 *
 *
 */
  const cacheKey = `${attr}.${unknownParameterName}.${camelCase}.${rename}`;
  if (attributeNameParseCache.has(cacheKey)) {
    log.trace("cache hit", cacheKey);
    return attributeNameParseCache.get(cacheKey);
  }

  /*                                              */
  const result: AttributeDefaultValue | AttributeEventValue = [
    attr,
    renameParam(paramName, camelCase, rename),
    modifier as Modifier,
    identifier ?? true,
  ];

  log.debug("Parsed Attribute", attr, result);
  return withCache(attributeNameParseCache, cacheKey, result);
}
