import type { EventTopicDefinition, NexusEventListener } from "./event.types";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function defineEvent<T = void>(
  ...listeners: NexusEventListener<T>[]
): EventTopicDefinition<T> {
  return { [Symbol.onexus.event]: listeners } as never;
}

/**
 *
 */
export const createEventTopic = defineEvent;
