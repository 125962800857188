import { deprecated } from "@gr-common/head/tools";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                 */
/*                                                                  */
export function cloneFunction<F extends Function>(functionReference: F): F {
  deprecated("o_util.core.cloneFunction", "this is dangerous, do not use");

  let key;

  if (typeof functionReference !== "function") {
    return functionReference;
  }

  /**
 *
 *
 *
 */
  /*                                                                        */
  function temp() {
    /*                                                         */
    return functionReference.apply(null, arguments);
  }

  /*                                            */
  for (key in functionReference) {
    /*                                             */
    if (functionReference.hasOwnProperty(key)) {
      /*                                                          */
      (temp as any)[key] = (functionReference as any)[key];
    }
  }

  /*                                                          */
  return temp as any;
}
/*                */
