import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { exists } from "./exists";
import { get } from "./get";
import { remove } from "./remove";
import { set } from "./set.js";
import { SAMESITE_VALUES } from "./utils";

export { SAMESITE_VALUES } from "./utils";
export type { SamesiteValues } from "./utils";

export { exists } from "./exists";
export { get } from "./get";

export { remove } from "./remove";
export type { RemoveCookieOptions } from "./remove";

export { set } from "./set";
export type { SetCookieOptions } from "./set";

assignNamespace(o_util, "cookie", {
  SAMESITE_VALUES,
  exists,
  get,
  remove,
  set,
});
