/*                                                   */
import { o_global } from "@gr-common/head/namespaces";
import { toggle } from "@gr-exports/head/toggle";

import * as browser from "../browser/index.js";
import * as animation from "../animation/head.js";
import * as cookie from "../cookie/index.js";
import * as core from "../core/index.js";
import * as dom from "../dom/head.js";
import * as event from "../event/index.js";
import * as history from "../history/index.js";
import * as misc from "../misc/head.js";
import * as hardcore from "../hardcore/index.js";
import { getNewScrollPosition } from "../animation/utils.js";
import { ajaxLegacy } from "./ajax/ajax.legacy.js";

if (toggle.get("assets_legacy_helper_enabled", true)) {
  o_global.helper ||= {};
  const { helper } = o_global;

  /*                        */

  helper.bind = core.bind;
  helper.clone = core.clone;
  helper.convertStringToFunction = core.convertStringToFunction;
  helper.extend = core.extend;
  helper.removeFromArray = core.removeFromArray;
  helper.isPlainObject = core.isPlainObject;
  helper.isEmptyObject = core.isEmptyObject;
  helper.cloneFunction = (core as any).cloneFunction;

  /*                          */
  helper.cookieExist = cookie.exists;
  helper.getCookie = cookie.get;
  helper.getCookieValue = cookie.get;
  helper.removeCookie = cookie.remove;
  helper.setCookie = cookie.set;

  /*                         */
  helper.delegate = event.delegate;
  helper.stopEvent = event.stop;
  helper.whichTransitionEvent = event.whichTransitionEndEvent;
  helper.addEvent = (event as any).add;
  helper.getEventTarget = (event as any).getTarget;
  helper.preventDefault = (event as any).preventDefault;
  helper.removeEvent = (event as any).remove;
  helper.stopPropagation = (event as any).stopPropagation;

  /*                       */
  helper.hasClassInParents = dom.hasClassInParents;
  helper.getParentByClassName = dom.getParentByClassName;
  helper.stringToDocumentFragment = dom.stringToDocumentFragment;
  helper.hasClass = (dom as any).hasClass;
  helper.removeClass = (dom as any).removeClass;
  helper.toggleClass = (dom as any).toggleClass;
  helper.getElementsByClassname = (dom as any).getElementsByClassname;
  helper.addClass = (dom as any).addClass;

  /*                             */
  helper.Easings = animation.easings;
  helper.requestAnimFrame = requestAnimationFrame;
  helper._getNewScrollPosition = getNewScrollPosition;
  helper.scrollTo = animation.scrollTo;

  /*                        */
  helper.ajax = ajaxLegacy;

  /*                           */
  helper.getScrollbarWidth = browser.getScrollbarWidth;
  helper.getStyle = browser.getStyle;
  helper.isIE8 = browser.isIE8;

  /*                           */
  helper.setScrollRestoration = history.setScrollRestoration;
  helper.resetScrollRestoration = history.resetScrollRestoration;

  /*                        */
  helper.isResponsive = misc.isResponsive;
  helper.isValidMouseButton = misc.isValidMouseButton;
  helper.setVendorStyle = misc.setVendorStyle;

  /*                            */
  helper.evalScript = hardcore.evalScript;
  helper.executeInlineScripts = hardcore.executeInlineScripts;

  /*                     */
  /*                                                   */
  o_global.referrer = document.referrer;

  /*                                                   */
  o_global.getCookieValue = helper.getCookieValue;
  o_global.convertStringToFunction = helper.convertStringToFunction;
  o_global.isResponsive = helper.isResponsive;
}
