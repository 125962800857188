import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { easings } from "./easings";
import { fadeIn, fadeOut } from "./fade";
import { scrollTo } from "./scrollTo";
import { transition, transitions } from "./transition";
import { requestAnimFrame } from "./utils";

export { scrollTo } from "./scrollTo";
export type { EasingFunc, Easings } from "./easings";
export { easings } from "./easings";
export { transition, transitions } from "./transition";
export { fadeIn, fadeOut } from "./fade";
export { requestAnimFrame } from "./utils";

assignNamespace(o_util, "animation", {
  easings,
  fadeIn,
  fadeOut,
  requestAnimFrame,
  scrollTo,
  transition,
  transitions,
});
