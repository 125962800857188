import type { NexusEventsMap, NexusEventTarget, TargetStore } from "./store.types";
import { getStore } from "./utils";

export const storeEvents = [
  "invokefunction",
  "invokedfunction",
  "assignfunction",
  "unsetfunction",
] as const satisfies (keyof NexusEventsMap)[];

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function dispatchStoreEvent<K extends keyof NexusEventsMap>(
  store: TargetStore,
  type: K,
  detail?: NexusEventsMap[K],
): boolean {
  return store.eventTarget?.dispatchEvent(new CustomEvent(type, { detail })) ?? true;
}

export function createEventTarget(): NexusEventTarget | undefined {
  try {
    return new EventTarget() as never;
    /*                  */
  } catch (error) {
    return undefined;
  }
}

/**
 *
 *
 *
 *
 *
 */
export function setupEventProxies(store: TargetStore, propStore: TargetStore): void {
  storeEvents.forEach((eventType) => {
    /*                                                               */
    setupEventTarget(propStore)?.addEventListener(eventType, (e) => {
      dispatchStoreEvent(store, eventType, e.detail);
    });
  });
}

export function setupEventTarget(store: TargetStore): NexusEventTarget | undefined {
  if (store.eventTarget) {
    return store.eventTarget;
  }

  store.eventTarget = createEventTarget();

  Object.values(store.members).forEach((member) => {
    const propStore = getStore(member);
    setupEventProxies(store, propStore);
  });

  return store.eventTarget;
}
