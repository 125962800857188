import { scope } from "../../debug/debug";

export function setOGlobal<K extends keyof OttoGlobal, T extends OttoGlobal[K]>(
  name: K,
  obj: T,
): T {
  (window as unknown as Record<string, Record<string, unknown> | OttoGlobal>).o_global =
    window.o_global || {};

  /*                                                                  */
  (window.o_global as unknown as Record<string, T>)[name] =
    (window.o_global as unknown as Record<string, T>)[name] || obj;

  return (window.o_global as unknown as Record<string, T>)[name];
}

/**
 *
 */
const topicValidationPattern = /^[\w-]{2,}\.[\w-]{3,}(\.[\w-]{3,})+$/;

/**
 *
 *
 */
export function validateTopic(topic: string): void {
  if (!(typeof topic === "string" && topicValidationPattern.test(topic))) {
    throw new Error(
      `eventQBus: topic name '${topic}' is invalid, please match regex syntax '${topicValidationPattern.toString()}' (e.g. "assets.rum.fired")`,
    );
  }
}

/**
 *
 */
const moduleValidationPattern = /^@?[\w-]{2,}\.[\w-]{3,}(\.[\w-]{3,})+$/;

export function validateModuleId(moduleId: string): void {
  if (!(typeof moduleId === "string" && moduleValidationPattern.test(moduleId))) {
    throw new Error(
      `eventQBus: module name '${moduleId}' is invalid, please match regex syntax '${moduleValidationPattern.toString()}' (e.g. "assets.global-resources.head")`,
    );
  }
}

/**
 *
 *
 *
 */
export function toPattern<T extends string | RegExp | undefined>(
  pattern?: T,
): T extends undefined ? RegExp | undefined : RegExp {
  return typeof pattern === "string" ? new RegExp(pattern) : (pattern as never);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function arrayRemove<T>(array: T[], item: T): T | undefined {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
    return item;
  }
  return undefined;
}

export function arrayContainsAll<T>(toContain: T[], toBeContained: T[]): boolean {
  return (
    toBeContained.filter((tbc) => toContain.some((tc) => tc === tbc)).length ===
    toBeContained.length
  );
}

export function arrayEqualsAll<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.length === arr2.length && arrayContainsAll(arr1, arr2);
}

/**
 *
 *
 *
 *
 *
 */
export function filterByPattern(list: string[], pattern?: RegExp): string[] {
  return pattern ? list.filter((i) => pattern.test(i)) : list.slice();
}

/**
 *
 *
 *
 */
export function callbackToResolve<T>(resolve: (args: T[]) => void): (...args: T[]) => void {
  return (...args) => resolve(args);
}

export const loggerScope = scope("event-q-bus");
