import { dispatchEvent } from "../event/private.js";
import { invokeQueueHandlerAsync, invokeQueueHandlerSync } from "../function/private.js";
/*                                       */
import {
  applyHandler,
  callHandler,
  dispatchHandler,
  isSetHandler,
  subscribeHandler,
  assignEventHandler,
  setHandler,
  thenHandler,
  toStringHandler,
  withHandler,
  assignNamespaceHandler,
  assignFunctionHandler,
  addEventListenerHandler,
  removeEventListenerHandler,
} from "./getters.js";
import type { GetterHandler } from "../store/store.types.js";
import * as symbols from "../utils/symbols.js";
import type { AnyFunction } from "../utils/utils.types.js";

/**
 *
 */
export const name = "otto";

export const gettersAsync = {
  set: setHandler,
  isSet: isSetHandler,
  on: subscribeHandler,
  receive: subscribeHandler,
  subscribe: subscribeHandler,
  sub: subscribeHandler,
  emit: dispatchHandler,
  dispatch: dispatchHandler,
  send: dispatchHandler,
  then: thenHandler,
  apply: applyHandler,
  call: callHandler,
  with: withHandler,
  toString: toStringHandler,
  assignEvent: assignEventHandler,
  assignNamespace: assignNamespaceHandler,
  assignFunction: assignFunctionHandler,
  addEventListener: addEventListenerHandler,
  removeEventListener: removeEventListenerHandler,
} as Record<string | symbol, undefined | typeof symbols.noop | GetterHandler>;

/**
 *
 */
export const reservedKeys: (string | symbol)[] = Object.keys(gettersAsync);

export const gettersSync = { ...gettersAsync, then: symbols.noop } as const;

export type ProxyConfig = [
  getters: typeof gettersAsync,
  dispatchEvent: typeof dispatchEvent,
  invokeQueueHandler: typeof invokeQueueHandlerAsync,
];

export const configAsync: ProxyConfig = [gettersAsync, dispatchEvent, invokeQueueHandlerAsync];

export const configSync: ProxyConfig = [gettersSync, dispatchEvent, invokeQueueHandlerSync];

export const proxies = new WeakMap<ProxyHandler<AnyFunction>, ProxyConfig>();

export function getProxyConfig(handler: ProxyHandler<AnyFunction>): ProxyConfig {
  return proxies.get(handler) as never;
}
