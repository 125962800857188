/*                          */
import { loggerScope } from "./helpers.js";
import { QBusTopic } from "./QBusTopic.js";

const log = loggerScope.scope("store");

/**
 *
 */
export interface QBusStoreOptions {
  /**
 *
 */
  isBusMode?: boolean;
  /**
 *
 *
 */
  ignoreAllScriptsExecuted?: boolean;
}

/**
 *
 *
 *
 *
 */
export class QBusStore {
  /**
 *
 */
  public readonly map: Record<string, QBusTopic> = {};

  /**
 *
 *
 *
 *
 *
 */
  public isBusMode: boolean;

  /**
 *
 *
 */
  public constructor(options: QBusStoreOptions = {}) {
    this.isBusMode = options.isBusMode || false;

    if (!options.ignoreAllScriptsExecuted) {
      this.initBusSwitchEvent();
    }
  }

  private initBusSwitchEvent(): void {
    /*                            */
    /*                            */
    if (typeof window.o_global?.eventLoader?.onAllScriptsExecuted === "function") {
      log.info("store", "Subscribe on onAllScriptsExecuted");
      window.o_global.eventLoader.onAllScriptsExecuted(1000, (): void => {
        log.info("store", "All Scripts were Executed, flush all events");
        /*                                        */
        this.isBusMode = true;
        /*                        */
        this.clearQueues();
      });
    }
  }

  /**
 *
 *
 *
 *
 */
  public get(topic: string): QBusTopic | undefined {
    return this.map[topic];
  }

  /**
 *
 *
 *
 */
  /*                      */
  /*                 */
  public clearAll(): void {
    /*                */
    Object.keys(this.map).forEach((k) => {
      delete this.map[k];
    });
  }
  /*                */

  /**
 *
 *
 */
  public clearQueues(): void {
    /*                                                         */
    Object.entries(this.map).forEach(([_, topic]) => {
      topic.clearQueue();
    });
  }

  /**
 *
 *
 *
 *
 *
 */
  public getOrAddTopic(topic: string): QBusTopic {
    const res = this.get(topic);
    if (!(res instanceof QBusTopic)) {
      this.map[topic] = new QBusTopic(topic);
      return this.map[topic];
    }
    return res;
  }
}
