import { Easings, easings } from "./easings";
import { scrollToInternal } from "./utils";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function scrollTo<T extends HTMLElement>(
  target: T | number,
  duration: number,
  easing: keyof Easings = "easeOutCubic",
): void {
  const windowTop = typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY;

  let position;
  if (typeof target === "object" && !!target.getBoundingClientRect) {
    position = target.getBoundingClientRect().top + windowTop - 20;
  } else if (typeof target === "number") {
    position = target;
  } else {
    throw new Error(
      "Invalid target for scrollTo() method. Element has to be an object with an offsetTop function or number, which stands for the page position.",
    );
  }

  /*                                                                           */
  const easingFnc = easings[easing];

  if (!easingFnc) {
    throw new Error(`Unknown easing function for scrollTo() method: ${easing}`);
  }

  /*                                                                                                 */
  let scrollBase = document.documentElement;
  const currentPos = scrollBase.scrollTop;

  let ratio;
  if (currentPos === 0) {
    ratio = window.devicePixelRatio ? window.devicePixelRatio : 1;
    scrollBase.scrollTop += Math.max(1, ratio, 1 / ratio);
    scrollBase = scrollBase.scrollTop > 0 ? scrollBase : document.body;
    scrollBase.scrollTop = 0;
  }

  /*                                              */
  const maxScroll = scrollBase.scrollHeight - scrollBase.offsetHeight;
  if (maxScroll < position) {
    position = maxScroll;
  }

  /*                                                                                                                                           */
  if (duration < 16) {
    scrollBase.scrollTop = position;
    return;
  }

  scrollToInternal(scrollBase, currentPos, position, 0, duration, easingFnc);
}
