import type { DispatchOptions, EventTopicDefinition, SubscriptionOptions } from "./event.types.js";
import * as symbols from "../utils/symbols.js";
import { assertNamespaceType } from "../utils/utils.js";
import type { Listener, TargetStore } from "../store/store.types.js";
import type { AnyFunction } from "../utils/utils.types.js";
import type { Unset } from "../proxy/proxy.types.js";

/**
 *
 *
 *
 *
 *
 */
export function isEventDefinition(value: unknown): value is EventTopicDefinition<unknown> {
  return (
    typeof value === "object" &&
    value !== null &&
    Object.prototype.hasOwnProperty.call(value, symbols.event)
  );
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function invokeListener(
  this: unknown,
  detail: unknown,
  prevous: unknown,
  [listener, options, controller]: Listener,
): unknown | Error {
  /*                                                          */
  if (options.onlyChanges && prevous === detail) {
    return undefined;
  }

  /*                                          */
  /*                                     */
  /*                                                                          */
  try {
    return listener.call(this, detail, prevous);
  } catch (e) {
    return e;
  } finally {
    /*                                                                         */
    if (options.once) controller.abort();
  }
}

export function addListener(
  store: TargetStore,
  listener: AnyFunction,
  options: SubscriptionOptions = {},
): Unset {
  if (typeof listener !== "function") {
    throw Error(`Invalid listener type ${typeof listener} provided. a function is expected`);
  }
  assertNamespaceType(store, "isEvent", "add listener to");

  /*                                                                                       */
  const mainController = new AbortController();

  const lsitenerEntry: Listener = [listener, options, mainController];
  store.listeners.push(lsitenerEntry);

  /*                                                      */
  /*                                                       */
  mainController.signal.addEventListener(
    "abort",
    () => store.listeners.splice(store.listeners.indexOf(lsitenerEntry), 1),
    { once: true },
  );

  if (store.retained && !options.skipRetained)
    invokeListener(store.detail, undefined, lsitenerEntry);

  if (options.signal) {
    /*                                                                */
    /*                              */
    options.signal.addEventListener("abort", () => mainController.abort(), {
      once: true,
      signal: mainController.signal, /*                                                  */
    });
  }

  return () => {
    const isDeleted = store.listeners.indexOf(lsitenerEntry) > -1;
    mainController.abort();
    return isDeleted;
  };
}

export function deleteRetentions(store: TargetStore): void {
  delete store.detail;
  delete store.retained;
}

export function dispatchEvent(
  thisArg: unknown,
  store: TargetStore,
  parameters: unknown[],
): Promise<(unknown | Error)[]> {
  assertNamespaceType(store, "isEvent", "emit an event on");

  let detail: unknown = parameters[0];
  let options = parameters[1] as DispatchOptions | undefined;
  if (!parameters[1] && detail && typeof detail === "object" && "retain" in detail) {
    options = detail as DispatchOptions;
    detail = undefined;
  } else {
    options ||= {};
  }

  const previous: unknown | undefined = options.retain ? store.detail : undefined;

  if (store.retention) {
    clearTimeout(store.retention);
    deleteRetentions(store);
  }

  if (options.retain) {
    store.retained = true;
    store.detail = detail;
  }

  /*                              */
  if (typeof options.retain === "number" && options.retain > 0 && options.retain < Infinity) {
    store.retention = setTimeout(() => {
      deleteRetentions(store);
    }, options.retain);
  }

  return new Promise((resolve) => {
    /*                             */
    const results = [...store.listeners].map(invokeListener.bind(thisArg, detail, previous));
    resolve(results);
  });
}
