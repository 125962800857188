import { addListener, isEventDefinition } from "../event/private.js";
import type { NexusNamespace } from "../namespace/namespace.types.js";
import type { ProxyTarget, TargetStore } from "./store.types.js";
/*                                       */
import { createProxy } from "../proxy/proxy.js";
/*                                       */
import { configSync } from "../proxy/proxyConfig.js";
import * as symbols from "../utils/symbols.js";
import { assertNamespaceFree } from "../utils/utils.js";
import type { AnyFunction } from "../utils/utils.types.js";
import type { AssignOptions, Unset } from "../proxy/proxy.types.js";
import type { NexusEventListener } from "../event/event.types.js";
import {
  assignMembers,
  cleanupStore,
  ensurePropStore,
  eventMembers,
  removeTargetProps,
  setupReady,
  setupTargetProps,
} from "./store.js";
import { invokeHandlerAsync } from "../function/private.js";
import { dispatchStoreEvent, storeEvents } from "./store.events.js";

/**
 *
 *
 *
 *
 *
 *
 */
export const unsetEvent = function unsetEvent(
  store: TargetStore,
  target: ProxyTarget,
  unsetListeners: Unset[],
): boolean {
  cleanupStore(store, target);
  setupReady(store);
  setupTargetProps(target, ...assignMembers);
  return unsetListeners.map((d) => d()).every(Boolean);
};

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function assignEvent(
  store: TargetStore,
  target: ProxyTarget,
  listeners: NexusEventListener<unknown>[],
  options?: AssignOptions,
): Unset {
  assertNamespaceFree(store, "create event topic for", options);
  cleanupStore(store, target);

  if (options?.clean) {
    /*                                          */
    store.listeners.splice(0);
  }

  /*                                     */
  const unsetListeners = listeners.map((l) => addListener(store, l));

  /*                                                */
  store.isEvent = true;
  removeTargetProps(target, ...assignMembers);
  store.onReady(createProxy(target, configSync));

  /*                    */
  return unsetEvent.bind(undefined, store, target, unsetListeners);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function unsetFunction(
  store: TargetStore,
  target: ProxyTarget,
  handler: AnyFunction,
): boolean {
  const res = store.handler === handler;
  if (!res) {
    /*                            */
    return res;
  }

  cleanupStore(store, target);
  setupReady(store);
  setupTargetProps(target, ...eventMembers, ...assignMembers);
  store.log.debug("Unset function handler", handler);
  dispatchStoreEvent(store, storeEvents[3], { type: storeEvents[3], path: store.path, handler });

  return res;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function assignFunction(
  store: TargetStore,
  target: ProxyTarget,
  handler: AnyFunction,
  options?: AssignOptions,
): Unset {
  assertNamespaceFree(store, "set handler to", options);
  cleanupStore(store, target);

  store.isFunc = true;
  removeTargetProps(target, ...eventMembers, ...assignMembers);
  store.handler = handler as AnyFunction;
  store.onReady(createProxy(target, configSync));

  if (options?.clean) {
    /*                                            */
    store.invocations.splice(0);
  }

  /*                                */
  store.invocations.splice(0).forEach(invokeHandlerAsync, store);
  store.log.debug("Assign function handler", handler);
  dispatchStoreEvent(store, storeEvents[2], { type: storeEvents[2], path: store.path, handler });

  /*                    */
  return unsetFunction.bind(undefined, store, target, handler);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function unsetNamespace(
  store: TargetStore,
  target: ProxyTarget,
  unsetProps: Unset[],
): boolean {
  cleanupStore(store, target, true);
  setupReady(store);
  setupTargetProps(target, ...eventMembers, ...assignMembers);
  return unsetProps.map((d) => d()).every(Boolean);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function assignNamespace(
  store: TargetStore,
  target: ProxyTarget,
  ns: NexusNamespace<Record<string, unknown>>,
  options?: AssignOptions,
): Unset {
  assertNamespaceFree(store, "assign namespace to", options);
  cleanupStore(store, target, true);

  /*                                            */
  const unsetProps = Object.entries(ns).map(([key, val]) => {
    const [propStore, propTarget] = ensurePropStore(target, key);
    /*                                                               */
    return assignStoreValue(propStore, propTarget, val, options);
  });

  /*                                                    */
  store.isNs = true;
  removeTargetProps(target, ...eventMembers, ...assignMembers);
  store.onReady(createProxy(target, configSync));

  /*                    */
  return unsetNamespace.bind(undefined, store, target, unsetProps);
}

/**
 *
 *
 */
export function assignStoreValue(
  store: TargetStore,
  target: ProxyTarget,
  value: unknown,
  options?: AssignOptions,
): Unset {
  if (isEventDefinition(value)) {
    return assignEvent(store, target, value[symbols.event], options);
  }

  if (typeof value === "function") {
    return assignFunction(store, target, value as AnyFunction, options);
  }

  if (
    value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    Object.keys(value).length > 0
  ) {
    return assignNamespace(
      store,
      target,
      value as NexusNamespace<Record<string, unknown>>,
      options,
    );
  }

  throw Error(
    `Unexpected Input for: ${
      store.path
    }. Nexus expects input to be either a function, nexus definition, or an object with at least one property, given was: ${typeof value}`,
  );
}
