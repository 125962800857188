import { o_global } from "@gr-common/head/namespaces";
import { eventLoader, EventLoader } from "./Loader.js";

export { EventLoader, eventLoader };

o_global.EventLoader ||= EventLoader;
o_global.eventLoader ||= eventLoader();
o_global.onloadHandler ||=
  /*                                            */
  (cb) => o_global.eventLoader.onLoad(100, cb);
