import { window } from "@gr-common/head/namespaces";
import { createProxy } from "../lib/nexus/proxy/proxy.js";
import { configAsync, name } from "../lib/nexus/proxy/proxyConfig.js";
import { createStore } from "../lib/nexus/store/store.js";
export * from "../lib/nexus/index.js";
export * from "../lib/nexus/function/public.js";
export * from "../lib/nexus/event/public.js";
export * from "../lib/nexus/namespace/public.js";
const otto = import.meta.env.SSR ? createProxy(createStore(name)[1], configAsync) : window.otto ?? {};
export {
  otto
};
