import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { deprecated } from "@gr-common/head/tools";
import {
  addDelegationConfig,
  addEvent,
  findDelegation,
  findDelegationConfig,
  getContainerElement,
  queryDelegationTargets,
} from "./utils.js";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function delegate<
  T extends HTMLElement | Document,
  K extends keyof HTMLElementEventMap,
  S extends keyof HTMLElementTagNameMap,
  F extends (this: Element, ev: HTMLElementEventMap[K]) => void,
>(
  containerOrSelector: T | S | string,
  eventType: K,
  targetSelector: string,
  listener: F,
  options: boolean | AddEventListenerOptions = false,
): boolean {
  const container = getContainerElement(containerOrSelector);

  if (!container) {
    /*                   */
    return false;
  }

  let config = findDelegationConfig(container);
  if (!config) {
    config = addDelegationConfig(container);
  }

  /*                              */
  queryDelegationTargets(container, targetSelector).forEach((element) =>
    addEvent({ target: element, eventType, listener, options }),
  );

  /*                                                         */
  const delegation = findDelegation(config, eventType, targetSelector, listener);
  if (!delegation) {
    /*                                   */
    config.delegations.push({ listener, options, eventType, targetSelector });
  }

  return true;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function undelegate<
  T extends HTMLElement | Document,
  K extends keyof HTMLElementEventMap,
  S extends keyof HTMLElementTagNameMap,
  F extends (this: Element, ev: HTMLElementEventMap[K]) => void,
>(
  containerOrSelector: T | S | string,
  eventType: K,
  targetSelector: string,
  listener: F,
  options?: boolean | EventListenerOptions,
): boolean {
  const element = getContainerElement(containerOrSelector);

  if (!element) {
    /*                   */
    return false;
  }

  const config = findDelegationConfig(element);
  if (!config) {
    /*                                          */
    return false;
  }

  /*                                                       */
  const delegation = findDelegation(config, eventType, targetSelector, listener);
  if (!delegation) {
    /*                                      */
    return false;
  }

  /*                                */
  config.delegations.splice(config.delegations.indexOf(delegation), 1);

  /*                              */
  queryDelegationTargets(element, targetSelector).map((target) =>
    target.removeEventListener(eventType, listener, options),
  );

  return true;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function stop<T extends Event>(event: T): void {
  event.stopPropagation();
  event.preventDefault();
}

/**
 *
 *
 *
 *
 *
 */
export function whichTransitionEndEvent(): string {
  const el = document.createElement("fakeelement");

  const res = Object.entries({
    WebkitTransition: "webkitTransitionEnd",
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    DEFAULT: "",
  })
    .filter(([k]) => el.style[k as keyof CSSStyleDeclaration] !== undefined || k === "DEFAULT")
    .map(([, v]) => v)[0];

  return res;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                 */
export function add<
  T extends HTMLElement,
  K extends keyof HTMLElementEventMap,
  F extends (this: HTMLElement, ev: HTMLElementEventMap[K]) => void,
>(obj: T, type: K, fn: F, useCapture = false): void {
  deprecated("o_util.event.add", "use native addEventListener instead");
  obj.addEventListener(type, fn, useCapture);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function remove<
  T extends HTMLElement,
  K extends keyof HTMLElementEventMap,
  F extends (this: HTMLElement, ev: HTMLElementEventMap[K]) => void,
>(obj: T, type: K, fn: F): void {
  deprecated("o_util.event.remove", "use native removeEventListener instead");
  obj.removeEventListener(type, fn, false);
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function stopPropagation<T extends Event>(event: T): void {
  deprecated("o_util.event.stopPropagation", "use native event.stopPropagation instead");
  event.stopPropagation();
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getTarget<T extends Event>(event: T): EventTarget | null {
  deprecated("o_util.event.getTarget", "use native event.target instead");
  return event.target;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function preventDefault<T extends Event>(event: T): void {
  deprecated("o_util.event.preventDefault", "use native event.preventDefault instead");
  event.preventDefault();
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function trigger<T extends Element>(element: T, type: string): void {
  deprecated("o_util.event.trigger", "use native CustomElements instead");
  const event = document.createEvent("HTMLEvents");

  event.initEvent(type, true, true);
  element.dispatchEvent(event);
}
/*                */

assignNamespace(o_util, "event", {
  delegate,
  undelegate,
  stop,
  whichTransitionEndEvent,
  add,
  remove,
  stopPropagation,
  getTarget,
  preventDefault,
  trigger,
});
