/*                          */
import { arrayContainsAll, arrayEqualsAll, arrayRemove, validateModuleId } from "./helpers.js";
import { QBusStore } from "./QBusStore.js";
import { EventData } from "./QBusTopic.js";

/**
 *
 *
 */
export interface ModuleEventPayload {
  /**
 *
 */
  moduleId: string;
}

export interface ModuleInitParams {
  /**
 *
 */
  moduleId: string;
  /**
 *
 */
  dependencyIds: string | string[];
}

/**
 *
 */
export interface ModuleCallbackParams {
  /**
 *
 */
  moduleId: string;
  /**
 *
 */
  dependencyIds: string[];
}

export type ModuleListenerCallback<R = unknown> = (
  ...data: EventData<[ModuleCallbackParams]>
) => R | undefined | Promise<R | undefined>;

/**
 *
 */
export interface ModuleListener {
  /**
 *
 */
  moduleId: string;
  /**
 *
 */
  dependencyIds: string[];
  /**
 *
 */
  callbacks: ModuleListenerCallback[];
}

export interface ModuleEventsManagerOptions {
  store: QBusStore;
  /**
 *
 */
  ignoreModuleEvents?: boolean;
}

/**
 *
 */
export type ModuleEventTypes = "loaded" | "initialized";

export type ModuleEventName<E extends ModuleEventTypes> = `assets.module.${E}`;

export function moduleEventName<E extends ModuleEventTypes>(event: E): ModuleEventName<E> {
  return `assets.module.${event}`;
}

/**
 *
 *
 */
export class ModuleEventsManager {
  public readonly store: QBusStore;

  public readonly ignoreModuleEvents: boolean | undefined;

  constructor(options: ModuleEventsManagerOptions) {
    this.store = options.store;
    this.ignoreModuleEvents = options.ignoreModuleEvents;
    this.init();
  }

  /**
 *
 *
 */
  public init(): void {
    if (!this.ignoreModuleEvents) {
      this.initModuleLoadEvent();
      this.initModuleInitializedEvent();
    }
  }

  /**
 *
 *
 *
 *
 */
  private initModuleEvent<E extends ModuleEventTypes>(
    event: ModuleEventName<E>,
    modules: string[],
    listeneres: ModuleListener[],
  ): void {
    const topic = this.store.getOrAddTopic(event);

    topic.addListener((data: ModuleEventPayload) => {
      if (!modules.includes(data.moduleId)) {
        /*                                           */
        modules.push(data.moduleId);
      }

      /*                                                                  */
      [...listeneres].forEach((l) => {
        const fullFilled = arrayContainsAll(modules, l.dependencyIds);

        /*                                                                       */
        if (fullFilled) {
          arrayRemove(listeneres, l);
          l.callbacks.forEach((c) => c({ moduleId: l.moduleId, dependencyIds: l.dependencyIds }));
        }
      });
    });
  }

  /**
 *
 */
  public readonly loadedModules: string[] = [];

  /**
 *
 */
  public readonly onModuleLoadedListeners: ModuleListener[] = [];

  /**
 *
 */
  private initModuleLoadEvent(): void {
    this.initModuleEvent(
      moduleEventName("loaded"),
      this.loadedModules,
      this.onModuleLoadedListeners,
    );
  }

  /**
 *
 */
  public readonly initializedModules: string[] = [];

  /**
 *
 */
  public readonly onModuleInitializedListeners: ModuleListener[] = [];

  /**
 *
 */
  private initModuleInitializedEvent(): void {
    this.initModuleEvent(
      moduleEventName("initialized"),
      this.initializedModules,
      this.onModuleInitializedListeners,
    );
  }

  /*                                              */
  private addModuleListener(
    params: ModuleCallbackParams,
    callback: ModuleListenerCallback,
    listeners: ModuleListener[],
  ): ModuleListener {
    validateModuleId(params.moduleId);
    params.dependencyIds.forEach(validateModuleId);

    let listener = listeners.find(
      (l) =>
        params.moduleId === l.moduleId && arrayEqualsAll(params.dependencyIds, l.dependencyIds),
    );

    if (!listener) {
      listener = { moduleId: params.moduleId, dependencyIds: params.dependencyIds, callbacks: [] };
      listeners.push(listener);
    }

    if (!listener.callbacks.includes(callback)) {
      listener.callbacks.push(callback);
    }

    return listener;
  }

  /**
 *
 *
 *
 *
 *
 */
  public addModuleLoadListener(
    params: ModuleCallbackParams,
    callback: ModuleListenerCallback,
  ): ModuleListener {
    return this.addModuleListener(params, callback, this.onModuleLoadedListeners);
  }

  /**
 *
 *
 *
 *
 *
 */
  public addModuleInitializedListener(
    params: ModuleCallbackParams,
    callback: ModuleListenerCallback,
  ): ModuleListener {
    return this.addModuleListener(params, callback, this.onModuleInitializedListeners);
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  /*                                              */
  private emitModuleListener(
    modules: string[],
    listeneres: ModuleListener[],
    listener: ModuleListener,
  ): Promise<unknown> | unknown {
    const registered = listeneres.includes(listener);
    const fullFilled = arrayContainsAll(modules, listener.dependencyIds);

    if (fullFilled && registered) {
      arrayRemove(listeneres, listener);

      return Promise.all(
        listener.callbacks.map((c) =>
          c({ moduleId: listener.moduleId, dependencyIds: listener.dependencyIds }),
        ),
      );
    }

    return undefined;
  }

  /**
 *
 *
 *
 *
 */
  public emitModuleLoadedListener(listener: ModuleListener): Promise<unknown> | unknown {
    return this.emitModuleListener(this.loadedModules, this.onModuleLoadedListeners, listener);
  }

  /**
 *
 *
 *
 *
 */
  public emitModuleInitializedListener(listener: ModuleListener): Promise<unknown> | unknown {
    return this.emitModuleListener(
      this.initializedModules,
      this.onModuleInitializedListeners,
      listener,
    );
  }
}
