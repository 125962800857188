/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function get<T extends string>(name: string): T | undefined {
  const parts = document.cookie.split(/;\s*/);
  const n = `${name}=`;

  const cookieValue = parts.find((part) => {
    return part.startsWith(n);
  });

  if (cookieValue) {
    /*                                                          */
    return decodeURIComponent(cookieValue.replace(n, ""));
  }

  return undefined;
}
