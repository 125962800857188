import { addListener } from "../event/private.js";
import {
  DispatchParameters,
  NexusEventListener,
  SubscriptionOptions,
} from "../event/event.types.js";
/*                                       */
import { assignEvent, assignFunction, assignNamespace, assignStoreValue } from "../store/assign.js";
import { AnyFunction } from "../utils/utils.types.js";
import { GetterHandler, NexusEventsMap } from "../store/store.types.js";
import { AssignOptions, ExtendedPromise, InvocationOptions, Unset } from "./proxy.types.js";
import { withOptions } from "./promise.js";
import { NexusNamespace } from "../namespace/namespace.types.js";
import { assertNamespaceType } from "../utils/utils.js";
import { getStoreType } from "../store/utils.js";
import { setupEventTarget } from "../store/store.events.js";

/**
 *
 */
export const applyHandler = function applyHandler(store, _, config) {
  return (thisArg: unknown, argArray: unknown[]): unknown => {
    const [, dispatchEvent, invokeQueueHandler] = config;

    /*                                                 */
    /*                                */
    if (store.isEvent) {
      return dispatchEvent(thisArg, store, argArray);
    }

    /*                                                                           */
    assertNamespaceType(store, "isFunc", "invoke");
    return invokeQueueHandler(thisArg, store, argArray);
  };
} satisfies GetterHandler;

/**
 *
 */
export const withHandler = function withHandler(store) {
  return (options: InvocationOptions) => withOptions(store.ready, store, options);
} satisfies GetterHandler;

/**
 *
 */
export const callHandler = function callHandler(store, target, config) {
  return (thisArg: unknown, ...argArray: unknown[]): unknown =>
    applyHandler(store, target, config)(thisArg, argArray);
} satisfies GetterHandler;

/**
 *
 *
 */
export const setHandler = function setHandler(store, target) {
  return (value: unknown, options?: AssignOptions): Unset =>
    assignStoreValue(store, target, value, options);
} satisfies GetterHandler;

/**
 *
 */
export const isSetHandler = function isSetHandler(_, target) {
  return target.isSet;
} satisfies GetterHandler;

/**
 *
 */
export const thenHandler = function thenHandler(store) {
  return (callback: AnyFunction): ExtendedPromise<unknown> => {
    const res = store.ready.then(callback) as ExtendedPromise<unknown>;
    return res;
  };
} satisfies GetterHandler;

/**
 *
 *
 */
export const subscribeHandler = function subscribeHandler(store) {
  return (listener: AnyFunction, options?: SubscriptionOptions): Unset =>
    addListener(store, listener, options);
} satisfies GetterHandler;

/**
 *
 */
export const dispatchHandler = function (store, t, config) {
  return function dispatch(this: unknown, ...args: DispatchParameters<unknown>) {
    return config[1](this, store, args);
  };
} satisfies GetterHandler;

/**
 *
 */
export const toStringHandler = ((store, target) => (): string => {
  const members = Object.keys(store.members);
  const { isEvent, isNs, isFunc, isReady, isSig } = store;
  const isWrapper = !isEvent && !isFunc && !isNs && !isSig;

  const desc = [`[${getStoreType(store)}: ${(store.handler ?? target).name}`];
  if (!isWrapper) {
    desc.push(" (");
  }
  if (isEvent) {
    desc.push(`listeners: ${store.listeners.length}`);
  }
  if (isFunc) {
    desc.push(`pending invocations: ${store.invocations.length}, isSet: ${target.isSet}`);
  }
  if (isNs) {
    desc.push(`members: ${members.join()}, ready: ${!!isReady}`);
  }
  if (!isWrapper) {
    desc.push(")");
  }
  desc.push("]");

  return desc.join("");
}) satisfies GetterHandler;

/**
 *
 *
 */
export const assignEventHandler = function assignEventHandler(store, target) {
  return (listeners: NexusEventListener<unknown>[], options?: AssignOptions): Unset =>
    assignEvent(store, target, listeners ?? [], options);
} satisfies GetterHandler;

/**
 *
 *
 */
export const assignNamespaceHandler = function assignNamespaceHandler(store, target) {
  return (value: NexusNamespace<Record<string, unknown>>, options?: AssignOptions): Unset =>
    assignNamespace(store, target, value, options);
} satisfies GetterHandler;

/**
 *
 *
 */
export const assignFunctionHandler = function assignFunctionHandler(store, target) {
  return (value: AnyFunction, options?: AssignOptions): Unset =>
    assignFunction(store, target, value, options);
} satisfies GetterHandler;

/**
 *
 */
export const addEventListenerHandler = function addEventListenerHandler(store) {
  return <K extends keyof NexusEventsMap>(
    type: K,
    /*                                                          */
    listener: (ev: CustomEvent<NexusEventsMap[K]>) => any,
    options?: boolean | AddEventListenerOptions,
  ) => {
    const eventTarget = setupEventTarget(store);
    eventTarget?.addEventListener(type, listener, options);
  };
} satisfies GetterHandler;

/**
 *
 */
export const removeEventListenerHandler = function removeEventListenerHandler(store) {
  return <K extends keyof NexusEventsMap>(
    type: K,
    /*                                                          */
    listener: (ev: CustomEvent<NexusEventsMap[K]>) => any,
    options?: boolean | AddEventListenerOptions,
  ) => {
    store.eventTarget?.removeEventListener(type, listener, options);
  };
} satisfies GetterHandler;
