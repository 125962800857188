import { levelColors, LogLevels, logLevels, levelRules } from "./level.js";
import { logExtFunctions } from "./extensions.js";
import { status, DebugConfig } from "./status.js";

/*                      */
/*                                                                        */
function createWriters() {
  return Object.fromEntries(
    ([...logLevels, ...logExtFunctions] as const satisfies (keyof Console)[]).map(
      /*                                  */
      (k) => [k, (console[k] ?? console.log) as Console[typeof k]] as const,
    ),
  );
}

/**
 *
 */
const writers = createWriters();

/*                      */
function createLevelMap(): Map<
  LogLevels,
  [writer: typeof console.log, index: number, color: string]
> {
  return new Map(logLevels.map((l, i) => [l, [writers[l], i, levelColors[i]]]));
}

/**
 *
 */
const levelMap = createLevelMap();

export const colorCache = new Map<string, string>();

/*                      */
function computeLightnessOpts(): [number, number] {
  const darkModeMatch = window.matchMedia("(prefers-color-scheme: dark)");
  return darkModeMatch.matches ? [50, 50] : [0, 60];
}
const lightnessOpts = computeLightnessOpts();

/**
 *
 *
 *
 */
function randomColor(scopeName: string): string {
  const cached = colorCache.get(scopeName);
  if (cached) {
    return cached;
  }

  const lightness = Math.random() * lightnessOpts[1] + lightnessOpts[0];
  const result = `hsl(${Math.random() * 360}, 100%, ${lightness}%)`;
  colorCache.set(scopeName, result);
  return result;
}

export const formatCache = new Map<
  `${LogLevels}:${string}`,
  [format: string, formatArgs: string[]]
>();

function getFormat(
  level: LogLevels,
  levelColor: string,
  scopeName: string,
  config: DebugConfig,
): [string, string[]] {
  if (!config.colors) {
    return [`[ ${level} ] ${scopeName}:`, []];
  }

  const cacheKey = `${level}:${scopeName}` as const;
  let cached = formatCache.get(cacheKey);
  /*                                       */
  if (!cached) {
    cached = [
      `[ %c${level.padStart(5)}%c ] %c${scopeName}%c:`,
      [`color: ${levelColor}`, "", `font-weight: bold; color: ${randomColor(scopeName)};`, ""],
    ];
    formatCache.set(cacheKey, cached);
  }

  return cached;
}

/**
 *
 *
 */
export type Writer = (
  level: LogLevels,
  scopeName: string,
  /*                                                          */
  data: any[],
  writer?: keyof typeof writers,
) => void;

export const writeLog: Writer = (level, scopeName, data, writer): void => {
  /*                                                               */
  /*                                                                */
  const st = status();

  /*                                                                    */
  if (!levelMap.has(level)) {
    writers.debug("invalid log level", level);
    return undefined;
  }

  /*                                                                          */
  /*                                             */
  const current = levelMap.get(level)!;
  const active = levelMap.get(st.level)!;

  /*                                                                  */
  if (!levelRules[st.rule](current[1], active[1]) || !st.pattern?.test(scopeName)) {
    return undefined;
  }

  /*                                                     */
  if (writer && writers[writer]) {
    return writers[writer](...data);
  }

  /*                                  */
  /*                                                                                */
  /*                                   */
  const format = getFormat(level, current[2], scopeName, st);
  /*                                                          */
  /*                                                                     */
  const userFormat = typeof data[0] === "string" ? data.shift() : "";
  /*                                                                */
  return current[0](`${format[0]} ${userFormat}`, ...format[1], ...data);
};
