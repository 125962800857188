function jsonParseSafe(value) {
  try {
    return typeof value === "string" ? JSON.parse(value) : void 0;
  } catch {
    return void 0;
  }
}
function jsonParseOrUse(value) {
  return jsonParseSafe(value) ?? value;
}
export {
  jsonParseOrUse,
  jsonParseSafe
};
