import { o_util } from "@gr-common/head/namespaces";
import { otto } from "./nexus.js";
const apm = otto.apm ?? {};
const agent = apm.agent;
const getCurrentTransaction = apm.getCurrentTransaction;
const startSpan = apm.startSpan;
const captureError = apm.captureError;
const mark = apm.mark;
const addLabels = apm.addLabels;
const sendCustomError = apm.sendCustomError;
const apmSync = o_util.apm ?? {};
const runWithSpan = apmSync.runWithSpan;
const withSpan = apmSync.withSpan;
export {
  addLabels,
  agent,
  apm,
  apmSync,
  captureError,
  getCurrentTransaction,
  mark,
  runWithSpan,
  sendCustomError,
  startSpan,
  withSpan
};
