import { AjaxOptions, AjaxCallback, XMLHttpRequestProcessed } from "./types";
import {
  setResponseType,
  setContentType,
  setWithCredentials,
  setRequestTimeout,
  handleDone,
  handleRequestTimeout,
  sanitizeResponse,
  setHeaders,
} from "./utils";

/**
 *
 *
 *
 *
 *
 *
 */
export function sendRequest(
  options: AjaxOptions,
  callback: AjaxCallback,
  counterObject: { active: number },
): XMLHttpRequest {
  const counter = counterObject;
  const request: XMLHttpRequestProcessed = new XMLHttpRequest();

  request.open(options.method.toUpperCase(), options.url, true);
  counter.active += 1;

  setResponseType(options, request);
  setContentType(options, request);
  setHeaders(options, request);
  setWithCredentials(options, request);
  const abortTimeout = setRequestTimeout(options, request);

  /*                                                         */
  request.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  const decrementCounter = (): void => {
    counter.active -= 1;
  };
  request.addEventListener("abort", decrementCounter);
  request.addEventListener("error", decrementCounter);

  request.onreadystatechange = handleDone(() => {
    handleRequestTimeout(abortTimeout);

    decrementCounter();
    sanitizeResponse(options, request);

    /*                      */
    /*                                                          */
    callback(request);
  });

  /*                            */
  request.send(options.data ? options.data : null);

  /*                                    */
  return request;
}
