import { withOptions } from "../proxy/promise.js";
import type { ExtendedPromise } from "../proxy/proxy.types.js";
import { dispatchStoreEvent, storeEvents } from "../store/store.events.js";
import type { Invocation, TargetStore } from "../store/store.types.js";
import type { AnyFunction } from "../utils/utils.types.js";

export function invokeHandlerAsync(this: TargetStore, invocation: Invocation): void {
  /*                               */
  /*                                               */
  /*                      */
  /*                                                 */
  /*                                  */

  /*                                              */
  if (!this.handler) {
    invocation[3](new Error("Handler is not set"));
    return;
  }

  const detailBase = {
    path: this.path,
    handler: this.handler,
    params: invocation[1],
    thisArg: invocation[0],
  };

  try {
    /*                                         */
    const invokeDetail = { type: storeEvents[0], ...detailBase };
    this.log.info("Invoke function handler", invokeDetail);
    dispatchStoreEvent(this, storeEvents[0], invokeDetail);

    /*                                              */
    const resultOrPromise = this.handler.apply(invocation[0], invocation[1]);

    /*                                        */
    const resultDetail = { type: storeEvents[1], result: resultOrPromise, ...detailBase };
    this.log.info("Function handler result", invokeDetail);
    dispatchStoreEvent(this, storeEvents[1], resultDetail);

    /*                                     */
    invocation[2](resultOrPromise);
  } catch (e) {
    /*                                */
    const errorDetail = { type: storeEvents[1], result: e, ...detailBase };
    this.log.warn("Function invocation failed", errorDetail);
    dispatchStoreEvent(this, storeEvents[1], errorDetail);

    /*                                        */
    invocation[3](e);
  }
}
/**
 *
 *
 *
 *
 *
 *
 */
export function invokeQueueHandlerAsync(
  thisArg: unknown,
  store: TargetStore,
  argArray: unknown[],
): unknown | Promise<unknown> {
  /*                                        */
  const res = new Promise((resolve, reject) => {
    const invocation: Invocation = [thisArg, argArray, resolve, reject];

    if (!store.handler) {
      /*                          */
      store.invocations.push(invocation);
      return;
    }

    invokeHandlerAsync.call(store, invocation);
  }) as ExtendedPromise<unknown>;

  /*                                               */
  res.with = (options) => withOptions(res, store, options);

  return res;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function invokeQueueHandlerSync(
  thisArg: unknown,
  store: TargetStore,
  argArray: unknown[],
): unknown | Promise<unknown> {
  const handler = store.handler as AnyFunction;
  if (import.meta.env.MODE === "test") {
    return handler?.apply(thisArg, argArray);
    /*                                                                        */
  }
  return handler.apply(thisArg, argArray);
}
