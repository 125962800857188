/*                                                   */
/*                          */
import { activate } from "../../debug/debug.js";
import {
  ModuleCallbackParams,
  moduleEventName,
  ModuleEventsManager,
  ModuleListener,
  ModuleListenerCallback,
} from "../utils/ModuleEvents.js";
import { QBusStore } from "../utils/QBusStore.js";
import type { EventCallback, EventData } from "../utils/QBusTopic.js";
import { callbackToResolve, setOGlobal } from "../utils/helpers.js";
import {
  addModuleSubscription,
  addSubscription,
  publish,
  removeSubscription,
} from "./qBusUtils.js";
import type { ModuleName, QBus, QBusEventMap, TopicName } from "./types.js";

/**
 *
 */
export interface QBusOptions {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  store?: QBusStore;

  moduleEvents?: ModuleEventsManager;
}

/**
 *
 *
 */
export class QBusImpl<Events extends QBusEventMap = QBusEventMap> implements QBus<Events> {
  /**
 *
 *
 */
  readonly store: QBusStore;

  /**
 *
 *
 */
  readonly moduleEvents: ModuleEventsManager;

  /**
 *
 *
 */
  constructor(options: QBusOptions = {}) {
    /*                                                */
    this.store =
      options.store ||
      window.o_global?.eventQBusStore ||
      setOGlobal("eventQBusStore", new QBusStore());

    this.moduleEvents =
      options.moduleEvents ||
      window.o_global.eventQBusModuleEvents ||
      setOGlobal("eventQBusModuleEvents", new ModuleEventsManager({ store: this.store }));
  }

  get isBusMode(): boolean {
    return this.store.isBusMode;
  }

  get isQueueMode(): boolean {
    return !this.store.isBusMode;
  }

  on<Topic extends keyof Events & TopicName>(
    topic: Topic,
    callback: EventCallback<Events[Topic]>,
  ): EventCallback<Events[Topic]> | null {
    return addSubscription({ store: this.store, topicName: topic, callback, singleRun: false });
  }

  with<CustomEvents extends QBusEventMap>(): QBus<CustomEvents> {
    return this as never;
  }

  once<Topic extends keyof Events & TopicName>(topic: Topic): Promise<Events[Topic]>;

  once<Topic extends keyof Events & TopicName>(
    topic: Topic,
    callback: EventCallback<Events[Topic]>,
  ): EventCallback<Events[Topic]> | null;

  once<Topic extends keyof Events & TopicName>(
    topic: Topic,
    callback?: EventCallback<Events[Topic]>,
  ): unknown {
    if (callback) {
      return addSubscription({ store: this.store, topicName: topic, callback, singleRun: true });
    }

    return new Promise((resolve) => {
      addSubscription({
        store: this.store,
        topicName: topic,
        callback: callbackToResolve(resolve),
        singleRun: true,
      });
    });
  }

  off<Topic extends keyof Events & TopicName>(
    topic: Topic,
    callback: EventCallback<Events[Topic]>,
  ): boolean {
    return removeSubscription<Events[Topic]>({
      store: this.store,
      topicName: topic,
      callback,
    });
  }

  emit<Topic extends keyof Events & TopicName>(
    topic: Topic,
    ...data: EventData<Events[Topic]>
  ): Promise<unknown[]> {
    return publish({ store: this.store, topicName: topic, data });
  }

  emitRetain<Topic extends keyof Events & TopicName>(
    topic: Topic,
    ...data: EventData<Events[Topic]>
  ): Promise<unknown[]> {
    return publish({ store: this.store, topicName: topic, data, retain: true });
  }

  onModuleLoaded(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
  ): Promise<ModuleCallbackParams>;

  onModuleLoaded(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
    callback: ModuleListenerCallback,
  ): ModuleListenerCallback | null;

  onModuleLoaded(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
    callback?: ModuleListenerCallback,
  ): unknown {
    const register = (params: ModuleCallbackParams, cb: ModuleListenerCallback): ModuleListener => {
      const listener = this.moduleEvents.addModuleLoadListener(params, cb);
      this.moduleEvents.emitModuleLoadedListener(listener);
      return listener;
    };

    const params = { moduleId, dependencyIds };
    if (callback) {
      return addModuleSubscription({ callback, params, register });
    }

    return new Promise((resolve) => {
      addModuleSubscription({ callback: resolve, params, register });
    });
  }

  emitModuleLoaded(moduleId: ModuleName): Promise<unknown> {
    return publish({
      store: this.store,
      topicName: moduleEventName("loaded"),
      data: [{ moduleId }],
    });
  }

  emitModuleInitialized(moduleId: ModuleName): Promise<unknown> {
    return publish({
      store: this.store,
      topicName: moduleEventName("initialized"),
      data: [{ moduleId }],
    });
  }

  onModuleInitialized(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
  ): Promise<ModuleCallbackParams>;

  onModuleInitialized(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
    callback: ModuleListenerCallback,
  ): ModuleListenerCallback | null;

  onModuleInitialized(
    moduleId: ModuleName,
    dependencyIds: ModuleName | ModuleName[],
    callback?: ModuleListenerCallback,
  ): unknown {
    const register = (params: ModuleCallbackParams, cb: ModuleListenerCallback): ModuleListener => {
      const listener = this.moduleEvents.addModuleInitializedListener(params, cb);
      this.moduleEvents.emitModuleInitializedListener(listener);
      return listener;
    };

    const params = { moduleId, dependencyIds };
    if (callback) {
      return addModuleSubscription({ callback, params, register });
    }

    return new Promise((resolve) => {
      addModuleSubscription({ callback: resolve, params, register });
    });
  }

  /**
 *
 */
  setLog = activate;
}
