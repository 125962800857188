import {
  type AttributeDefaultValue,
  type AttributeEventValue,
  isDefaultValueAttribute,
  isRefAttribute,
  isUb64Attribute,
} from "./nameParser.js";
import { loggerScope, withCache, mergeParameterValues, parseParameterValue } from "./utils.js";
import { AttributeNameBase, isRelevantAttribute, parseAttributeName } from "./nameParser.js";
import { DEFAULT_PARAMETER_OPTIONS, ParametersParserOptions, ParserOptions } from "./options.js";

export const UNNAMED_ATTRIBUTE = "unnamed";

const log = loggerScope.scope("parser");

/**
 *
 */
const attributeRefCache = new WeakMap<HTMLElement, Map<string, HTMLScriptElement>>();

/**
 *
 *
 *
 *
 *
 */
export function getRefElement(
  element: HTMLElement,
  selector: string,
): { textContent?: string | null } {
  if (!attributeRefCache.has(element)) {
    log.trace("Creating new ref cache for element", element);
    attributeRefCache.set(element, new Map());
  }
  /*                   */
  const elementCache = attributeRefCache.get(element)!;

  /*             */
  if (elementCache.has(selector)) {
    log.trace("Cache hit for ref element", selector);
    return elementCache.get(selector)!;
  }

  const refElement = element.querySelector<HTMLScriptElement>(selector);
  if (!refElement) {
    log.warn("Reference element not found", selector);
    return {};
  }

  /*                                      */
  return withCache(elementCache, selector, refElement);
}

/**
 *
 *
 *
 *
 */
export const attributeValueCache = new Map<string, unknown>();

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function getAttributeValue(
  element: HTMLElement,
  attr: AttributeDefaultValue | AttributeEventValue,
  /*                                                          */
  parametersOptions: ParametersParserOptions<any>,
): unknown | undefined {
  /*                                     */
  let value: string | null | undefined = element.getAttribute(attr[0])!;
  const {
    json = DEFAULT_PARAMETER_OPTIONS.json,
    array,
    boolean,
    object,
    primitive,
  } = parametersOptions[attr[1]] ?? {};

  if (isRefAttribute(attr)) {
    /*                                  */
    value = getRefElement(element, value).textContent;
  }

  if (value === null || value === undefined) {
    return undefined;
  }

  if (isUb64Attribute(attr) && !value) {
    throw new Error(`Value of parameter ${attr[0]} ${value} is empty amd not a valid base64 value`);
  }

  if (isUb64Attribute(attr)) {
    /*                    */
    value = atob(value!);
  }

  /**
 *
 */
  const cacheKey = `${value}.${json}.${array}.${boolean}.${object}.${primitive}`;
  if (attributeValueCache.has(cacheKey)) {
    log.trace("Cache hit for attribute value", value);
    return attributeValueCache.get(cacheKey);
  }

  /*                                   */
  const parsed = parseParameterValue(parametersOptions[attr[1]], value);
  return withCache(attributeValueCache, cacheKey, parsed);
}

type GetParametersAccumulator = Record<
  string,
  [
    attr: AttributeDefaultValue | AttributeEventValue,
    defaultValue?: ReturnType<typeof getAttributeValue>,
    identifierValue?: ReturnType<typeof getAttributeValue>,
  ]
>;

/**
 *
 *
 *
 *
 *
 */
export function toParamAccumulator(
  this: HTMLElement,
  /*                                                          */
  parametersOptions: ParametersParserOptions<any>,
  acc: GetParametersAccumulator,
  parsed: AttributeEventValue | AttributeDefaultValue,
): GetParametersAccumulator {
  const paramName = parsed[1];

  /*                              */
  if (!acc[paramName]) {
    acc[paramName] = [parsed];
  }

  /*                       */
  const paramData = acc[paramName];
  /*                                               */
  const value = getAttributeValue(this, parsed, parametersOptions);

  /*                  */
  if (isDefaultValueAttribute(parsed)) {
    paramData[1] = value;
    return acc;
  }

  /*                              */
  paramData[2] = value;
  return acc;
}

/**
 *
 *
 *
 */
export function guardUnnamed(
  unnamed: GetParametersAccumulator[string] | undefined,
): [defaultValue: Record<string, unknown>, identifierValue: Record<string, unknown>] {
  return [{ ...(unnamed?.[1] as object) }, { ...(unnamed?.[2] as object) }];
}

/**
 *
 *
 *
 *
 *
 *
 */
export function parseParameters<
  T extends Record<string, unknown>,
  O extends Record<string, unknown> = T,
>(
  base: AttributeNameBase,
  identifier: string,
  element: HTMLElement,
  parametersOptions: ParametersParserOptions<T> = {},
  options: Partial<ParserOptions<O>> = {},
): T {
  const { extraParameters = {} as T, expandUnnamedAs = UNNAMED_ATTRIBUTE } = options;

  /*                                             */
  const { [UNNAMED_ATTRIBUTE]: unnamed, ...attributeValueMap } = element
    .getAttributeNames()
    .map((a) => parseAttributeName(base, a, expandUnnamedAs, parametersOptions))
    .filter((a) => isRelevantAttribute(a, identifier))
    .reduce(toParamAccumulator.bind(element, parametersOptions), {});

  /*                                 */
  const unnamedParam = guardUnnamed(unnamed);

  /*                                                        */
  const keys = new Set<string>(
    [unnamedParam[0], attributeValueMap, unnamedParam[1], extraParameters].flatMap((m) =>
      Object.keys(m),
    ),
  );

  /*                                                                             */
  /*                                                                                           */
  /*    */
  const result = {} as Record<string, unknown>;
  keys.forEach((key) => {
    const attributeValue = attributeValueMap[key] ?? [];
    const mergeOptions = { ...parametersOptions[key] };

    result[key] = mergeParameterValues(
      mergeOptions,
      /*             */
      unnamedParam[0][key],
      attributeValue[1],
      /*                  */
      extraParameters[key],
      /*                         */
      unnamedParam[1][key],
      attributeValue[2],
    );
  });

  log.debug("Extracted parameters from element", result);
  return result as T;
}
