import type { TargetStore } from "../store/store.types.js";
import type { InvocationOptions } from "./proxy.types.js";

/**
 *
 *
 *
 *
 *
 *
 */
export function withOptions(
  promise: Promise<unknown>,
  store: TargetStore,
  { signal, timeout }: InvocationOptions,
): Promise<unknown> {
  const races: Promise<unknown>[] = [promise];

  if (timeout) {
    races.push(
      new Promise((_, reject) => {
        setTimeout(() => {
          reject(Error(`Could not provide ${store.path} within defined timeout: ${timeout}`));
        }, timeout);
      }),
    );
  }

  if (signal) {
    races.push(
      new Promise((_, reject) => {
        signal.addEventListener("abort", () => {
          reject(
            Error(`Processing of ${store.path} cancelled due to abort signal: ${signal.reason}`),
          );
        });
      }),
    );
  }

  return Promise.race(races);
}
