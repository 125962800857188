import { o_global } from "@gr-common/head/namespaces";
import { jsonParseOrUse } from "@gr-common/head/json";
import { DEFAULT_PARAMETER_OPTIONS, ParameterParserOptions } from "./options";

/**
 *
 *
 *
 *
 *
 */
export function withCache<K, T>(cache: Map<K, T>, key: K, data: NoInfer<T>): T {
  cache.set(key, data);
  return data;
}

/**
 *
 */
export const loggerScope = o_global.debug.scope("assets.attribute-parser");

/**
 *
 *
 *
 */
export function isPrimitive(value: unknown): boolean {
  return typeof value === "string" || typeof value === "number" || typeof value === "boolean";
}

/**
 *
 *
 *
 */
export function isArray(value: unknown): boolean {
  return Array.isArray(value);
}

/**
 *
 *
 */
export type ParameterTypeChecker = (
  name: string,
  values: [current: unknown, previous: unknown],
) => boolean;

export function parseParameterValue(
  options: Partial<ParameterParserOptions> | undefined,
  value: string,
): unknown {
  const {
    array,
    object,
    boolean,
    primitive,
    json = DEFAULT_PARAMETER_OPTIONS.json,
  } = options ?? {};

  if (array) {
    /*             */
    const parsed = jsonParseOrUse(value);
    if (parsed && !Array.isArray(parsed)) {
      throw new Error(`Value ${value} is not an array`);
    }
    return parsed ?? [];
  }

  if (object) {
    /*                                      */
    const parsed = jsonParseOrUse(value);
    if (parsed && (typeof parsed !== "object" || Array.isArray(parsed))) {
      throw new Error(`Value ${value} is not an object`);
    }
    return parsed ?? {};
  }

  if (boolean) {
    /*                                                           */
    return value !== "false";
  }

  if (primitive) {
    /*                                  */
    const parsed = jsonParseOrUse(value);
    if (parsed && !isPrimitive(parsed)) {
      throw new Error(`Value ${value} is not a primitive value`);
    }
    return parsed;
  }

  if (!json) {
    /*                                     */
    return value;
  }

  if (value === "") {
    /*                                   */
    return true;
  }

  /*                                */
  return jsonParseOrUse(value);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function mergeParameterValues<T>(
  options: Partial<ParameterParserOptions> | undefined,
  ...values: T[]
): T {
  const {
    array,
    object,
    boolean,
    primitive,
    json = DEFAULT_PARAMETER_OPTIONS.json,
  } = options ?? {};

  const res = values.reduce((acc, value) => {
    if (acc === undefined) {
      return value;
    }

    if (array) {
      return [...(acc as unknown[]), ...((value ?? []) as unknown[])] as T;
    }

    if (boolean || primitive || !json) {
      return value ?? acc;
    }

    if (object) {
      return { ...acc, ...value };
    }

    if (isPrimitive(value) || isPrimitive(acc)) {
      return value ?? acc;
    }

    if (isArray(value) || isArray(acc)) {
      return [...(acc as unknown[]), ...((value ?? []) as unknown[])] as T;
    }

    return { ...acc, ...value };
  });

  return res;
}
