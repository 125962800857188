/**
 *
 *
 *
 *
 *
 */
export function exists(name: string): boolean {
  const values = document.cookie.split(";");

  const reg = new RegExp(`^( )*${encodeURIComponent(name)}$`, "g");

  for (let i = 0; i < values.length; i += 1) {
    if (reg.test(values[i].split("=")[0])) {
      return true;
    }
  }

  return false;
}
