import { o_global } from "@gr-common/head/namespaces";

const log = o_global.debug.logger("assets.apm.errors");
const { apm, dom } = otto;

function onErrorHandler(event: ErrorEvent): void {
  log.info("Handle error on page load", event.error);
  apm.captureError(event.error);
}

function onUnhandledRejectionHandler(event: PromiseRejectionEvent): void {
  log.info("Handle unhandled rejection on page load", event.reason);
  apm.captureError(event.reason);
}

/**
 *
 *
 *
 */
export function initErrorHandling(): void {
  window.addEventListener("error", onErrorHandler);
  window.addEventListener("unhandledrejection", onUnhandledRejectionHandler);

  /*                                                   */
  /*                                                      */
  dom.onReady.subscribe(() => {
    window.removeEventListener("error", onErrorHandler);
    window.removeEventListener("unhandledrejection", onUnhandledRejectionHandler);
  });
}
