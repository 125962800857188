/*                                       */
import { window } from "@gr-common/head/namespaces";
import { createProxy } from "./proxy/proxy.js";
import { configAsync, name } from "./proxy/proxyConfig.js";
import { createStore } from "./store/store.js";
import * as symbols from "./utils/symbols.js";

Object.defineProperty(window, name, {
  value: createProxy(createStore(name)[1], configAsync),
  writable: false,
  configurable: false,
  enumerable: false,
});

/*                                                         */
Object.defineProperty(Symbol, "onexus", { value: symbols });
