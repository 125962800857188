import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { removeFromArray, toArray } from "./array";
import { cloneFunction } from "./deprecated";
import { bind, convertStringToFunction } from "./function";
import { clone, extend, isEmptyObject, isPlainObject } from "./object";
import { serialize, serializeForm, deserialize, coerce } from "./serialize";

export { removeFromArray, toArray } from "./array";
export { convertStringToFunction, bind } from "./function";
export { extend, isEmptyObject, isPlainObject, clone } from "./object";

export { serialize, serializeForm, deserialize, coerce } from "./serialize";
export type { Serializable } from "./serialize";

export { cloneFunction } from "./deprecated";

assignNamespace(o_util, "core", {
  bind,
  clone,
  cloneFunction,
  coerce,
  convertStringToFunction,
  deserialize,
  extend,
  isEmptyObject,
  isPlainObject,
  removeFromArray,
  serialize,
  serializeForm,
  toArray,
});
