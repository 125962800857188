import { assignNamespace } from "@gr-common/head/namespace-utils";
import { o_util } from "@gr-common/head/namespaces";
import { parseParameters } from "./parser.js";
import { mergeParameterValues } from "./utils.js";
import { parseAttributeName } from "./nameParser.js";

assignNamespace(o_util, "attributeParser", {
  parseParameters,
  mergeParameterValues,
  parseAttributeName,
});

export { parseParameters, mergeParameterValues, parseAttributeName };
