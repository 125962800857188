import { o_global } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import {
  activate,
  activateLegacy,
  setColors,
  deactivate,
  logger,
  scope,
  updateWriter,
  setLevel,
} from "./debug.js";
import { Level } from "./level.js";
import { status, updateStatus } from "./status.js";
import { readCookies } from "./cookie.js";

/**
 *
 */
export type GlobalDebug = {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  logger: typeof logger;

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  scope: typeof scope;

  /**
 *
 */
  Level: typeof Level;

  /**
 *
 */
  status: typeof status;

  /**
 *
 */
  setColors: typeof setColors;

  /**
 *
 */
  setLevel: typeof setLevel;
};

/*                            */
updateWriter(updateStatus(readCookies()));

/*                                              */
assignNamespace(o_global, "debug", {
  /*                               */
  activate,
  deactivate,
  logger,
  scope,
  Level,
  status,
  /*                                       */
  warn: logger("legacy.warn").warn,
  setColors,
  setLevel,
  activateLegacy,
});
