import { o_util } from "@gr-common/head/namespaces";
const cookie = o_util.cookie ?? {};
const exists = cookie.exists;
const get = cookie.get;
const remove = cookie.remove;
const set = cookie.set;
export {
  cookie,
  exists,
  get,
  remove,
  set
};
