import { scope } from "../../debug/debug.js";
import type { ProxyTarget, TargetStore } from "./store.types.js";

/**
 *
 */
export const nexusScope = scope("nexus");

/**
 *
 *
 *
 */
export function getStoreType(store: TargetStore): string {
  const candidate = (
    [
      [store.isNs, "Namespace"],
      [store.isEvent, "Event"],
      [store.isFunc, "Function"],
      [store.isSig, "Signal"],
    ] as const
  ).find(([v]) => !!v)?.[1];

  if (!candidate) {
    return "NexusWrapper";
  }

  if (store.isReady) {
    return candidate;
  }

  return `Pending ${candidate}`;
}

export const stores = new WeakMap<ProxyTarget, TargetStore>();

/**
 *
 *
 *
 */
export function getStore(target: ProxyTarget): TargetStore {
  return stores.get(target) as never;
}
