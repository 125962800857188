import { initAllPreloadScriptsLoaded, initOnLoad, initOnReady } from "./utils";
import { store } from "./store";

/**
 *
 */
export class EventLoader {
  /**
 *
 */
  public readonly store = store;

  constructor() {
    initOnReady();
    initOnLoad();
    initAllPreloadScriptsLoaded();
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  onReady<F extends EventListener>(priority: number, fn: F): void {
    if (!this.store.onReadyFired) {
      this.store.onReadyQueue.push({ priority, fn });
    } else {
      fn(this.store.onReadyEvent as Event);
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  onLoad<F extends EventListener>(priority: number, fn: F): void {
    if (!this.store.onLoadFired) {
      this.store.onLoadQueue.push({ priority, fn });
    } else {
      fn(this.store.onLoadEvent as Event);
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  onAllPreloadScriptsLoaded<F extends EventListener>(priority: number, fn: F): void {
    if (!this.store.allPreloadScriptsLoadedFired) {
      this.store.allPreloadScriptsLoadedQueue.push({ priority, fn });
    } else {
      fn(this.store.allPreloadScriptsLoadedEvent as Event);
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  onAllJavascriptLoaded<F extends EventListener>(priority: number, fn: F): void {
    if (!this.store.allJavascriptLoadedComplete) {
      this.store.allJavascriptLoadedQueue.push({ priority, fn });
    } else {
      fn({} as Event);
    }
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  onAllScriptsExecuted<F extends EventListener>(priority: number, fn: F): void {
    this.onAllPreloadScriptsLoaded(priority, fn);
  }
}

/**
 *
 *
 */
export function eventLoader(): EventLoader {
  return new EventLoader();
}
