/*                                                           */
import { ajax } from "./index.js";

/**
 *
 *
 *
 *
 *
 */
export function ajaxLegacy(
  url: string,
  contentCallback: Function,
  finishCallback: Function,
): Promise<void> {
  return ajax.get(url).then((xhr) => {
    if (typeof contentCallback === "function") {
      if (xhr.status === 200) {
        contentCallback(xhr.responseText, xhr.status);
      } else {
        contentCallback(
          '<div class="p_message p_message--error p_layer--error"><b>Entschuldigung!</b> Aufgrund von technischen Problemen kann der Inhalt leider nicht geladen werden. Bitte versuche es zu einem späteren Zeitpunkt erneut.</div>',
          xhr.status,
        );
      }
    }

    if (typeof finishCallback === "function") {
      finishCallback(xhr.status);
    }
  });
}
