import { QBusImpl } from "./QBus.js";
import type { QBus, QBusEventMap } from "./types.js";

export * from "./QBus.js";
export * from "./qBusUtils.js";
export * from "./types.js";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function eventQBus<Events extends QBusEventMap>(): QBus<Events> {
  return new QBusImpl();
}
