import { o_util } from "@gr-common/head/namespaces";
import { deprecated } from "@gr-common/head/tools";
import { assignNamespace } from "@gr-common/head/namespace-utils";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getScrollbarWidth(): number {
  const scrollDiv = document.createElement("div");
  let scrollbarWidth = 0;

  if (document.body.clientWidth < window.innerWidth) {
    scrollDiv.className = "p_scrollbarMeasure";

    document.body.appendChild(scrollDiv);
    scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
  }

  return scrollbarWidth;
}

/**
 *
 *
 *
 *
 */
export function findInUserAgent(needle: string): boolean {
  return window.navigator.userAgent.toLowerCase().indexOf(needle) !== -1;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                 */
export function isIE8(): boolean {
  /*                    */
  deprecated("o_util.browser.isIE8", "IE8 is doomed");
  return navigator.userAgent.indexOf("MSIE 8") > -1;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function getStyle<T extends Element>(elem: T, styleProp: string): string {
  deprecated("o_util.browser.getStyle", "use native getComputedStyle instead");
  return window.getComputedStyle(elem, null).getPropertyValue(styleProp);
}
/*                */

assignNamespace(o_util, "browser", {
  findInUserAgent,
  getScrollbarWidth,
  getStyle,
  isIE8,
});
