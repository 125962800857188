/**
 *
 */
export interface RemoveCookieOptions {
  /**
 *
 */
  path?: string;
  /**
 *
 */
  domain?: string;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function remove(name: string, options?: RemoveCookieOptions): void {
  const opts = options || {};
  let optionsString = "";

  optionsString += ` path=${opts.path ? opts.path : "/"};`;

  if (opts.domain) {
    optionsString += ` domain=${opts.domain};`;
  }

  document.cookie = `${encodeURIComponent(
    name,
  )}=;${optionsString} expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
