/**
 *
 */
export const loggerDataKey = Symbol("logger-data");

/**
 *
 */
export const logExtFunctions = [
  "table",
  "group",
  "groupCollapsed",
  "groupEnd",
] as const satisfies (keyof Console)[];
