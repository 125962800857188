export class TimeoutError extends Error {
  cause: unknown;

  /*                                            */
  name = "TimeoutError" as const;

  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    /*          */
    this.cause ??= options?.cause;
  }
}
