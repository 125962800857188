import { whichTransitionEndEvent } from "../event";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const transitions = Object.freeze({
  fadeOut: "p_animation__fadeOut",
  fadeIn: "p_animation__fadeIn",
});

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function transition<T extends HTMLElement, F extends (...args: never[]) => void>(
  target: T,
  transitionClass: string,
  callback?: F,
): void {
  if (typeof target === "object" && typeof transitionClass === "string") {
    const transitionEndEvent = whichTransitionEndEvent();
    const callbackHandler = (e?: Event): void => {
      if (typeof callback === "function") {
        callback();
      }

      if (e && e.target) {
        e.target.removeEventListener(transitionEndEvent, callbackHandler);
      }
    };

    if (
      transitionEndEvent &&
      typeof callback === "function" &&
      !target.classList.contains(transitionClass)
    ) {
      target.addEventListener(transitionEndEvent, callbackHandler);
      target.classList.add(transitionClass);
    } else {
      target.classList.add(transitionClass);
      callbackHandler();
    }
  } else {
    throw new Error("No target or class specified");
  }
}
