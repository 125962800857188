import { jsonParseSafe } from "@gr-common/head/json";

/**
 *
 */
export type ToggleValue = boolean | number;

/**
 *
 *
 *
 *
 *
 */
export type UserToggle =
  | [value: ToggleValue, allowOverride?: ToggleValue, payload?: string]
  | ToggleValue
  | undefined;

/**
 *
 *
 */
export type NormalizedToggle<T> = [value: boolean, allowOverride: boolean, payload: T];

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export type TogglesData = { [toggleName: string]: UserToggle };

export const COOKIE_PREFIX = "toggle_";

/**
 *
 */
export const cookieMap = { true: 1, false: 0, "1": 1, "0": 0 };

/**
 *
 *
 *
 *
 *
 */
export function normalize<T>(
  toggle: UserToggle,
  defaultValue: unknown,
  defaultPayload: T,
): NormalizedToggle<T> {
  /*                                                   */
  if (toggle === undefined) {
    return [!!defaultValue, false, defaultPayload];
  }

  /*                                  */
  if (Array.isArray(toggle)) {
    return [!!toggle[0], !!toggle[1], jsonParseSafe(toggle[2]) ?? defaultPayload];
  }

  /*                                            */
  return [!!toggle, false, defaultPayload];
}

/**
 *
 *
 *
 *
 *
 */
export function normalizeLegacy(
  toggle: UserToggle,
  defaultValue: unknown,
  defaultPayload: unknown,
): NormalizedToggle<unknown> {
  if (toggle === undefined) {
    return [!!defaultValue, false, defaultPayload];
  }

  /*                                                                              */
  if (!!toggle === false) {
    return [false, true, defaultPayload];
  }

  /*                                                        */
  return [true, false, defaultPayload];
}
