import { o_util } from "@gr-common/head/namespaces";
import { assignNamespace } from "@gr-common/head/namespace-utils";
import { data } from "./utils";

/**
 *
 *
 */
export function setScrollRestoration(scrollRestoration: ScrollRestoration): void {
  if (data.hasNative) {
    window.history.scrollRestoration = scrollRestoration;
  }
}

/**
 *
 */
export function resetScrollRestoration(): void {
  setScrollRestoration(data.initial as ScrollRestoration);
}

assignNamespace(o_util, "history", {
  setScrollRestoration,
  resetScrollRestoration,
});
