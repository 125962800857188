/**
 *
 */
export type ParserOptions<T> = {
  /**
 *
 *
 *
 *
 */
  expandUnnamedAs?: string;

  /**
 *
 *
 *
 *
 */
  extraParameters?: T;

  /**
 *
 *
 *
 *
 */
  detectBooleans?: boolean;
};

export const DEFAULT_PARSER_OPTIONS: ParserOptions<Record<string, unknown>> = {
  detectBooleans: true,
};

export const DEFAULT_PARAMETER_OPTIONS = {
  json: true,
  camelCase: true,
} as const satisfies ParameterParserOptions;

/**
 *
 *
 */
export type ParametersParserOptions<T> = { [P in keyof T]?: Partial<ParameterParserOptions> };

/**
 *
 */
export type ParameterParserOptions = {
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  array?: boolean;

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  boolean?: boolean;

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  primitive?: boolean;

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  json: boolean;

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  object?: boolean;

  /**
 *
 *
 *
 *
 */
  camelCase: boolean;

  /**
 *
 *
 *
 *
 *
 */
  rename?: string;
};
