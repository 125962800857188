import { deprecated } from "@gr-common/head/tools";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function clone<T extends object>(object: T): T {
  deprecated("o_util.core.clone", "use native Object.from instead");
  return Object.create(object);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function extend<T1 extends object, T2 extends object>(
  object1: T1,
  object2: T2,
  overwrite?: boolean,
  cloneObj?: boolean,
): T1 & T2 {
  const replace = typeof overwrite === "boolean" ? overwrite : true;
  const doClone = typeof cloneObj === "boolean" ? cloneObj : false;
  const result = doClone ? Object.create(object1) : object1;

  Object.keys(object2 || {}).forEach((key) => {
    if (replace || !(key in result)) {
      /*                                                          */
      (result as any)[key] = (object2 as any)[key];
    }
  });

  return result as T1 & T2;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function isPlainObject<T>(object: T | Element | Window): boolean {
  return (
    !!object &&
    Object.prototype.toString.call(object) === "[object Object]" &&
    !(object as Element).nodeType &&
    object !== (object as Window).window
  );
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function isEmptyObject<T extends Record<string, unknown>>(
  object: T,
  inherited?: boolean,
): boolean {
  const properties = [];

  if (!inherited) {
    return Object.keys(object).length === 0;
  }

  /*                                                          */
  for (const prop in object) {
    properties.push(prop);
  }

  return Object.keys(properties).length === 0;
}
