import { o_global } from "./namespaces";
const log = o_global.debug.logger("assets.global-resources.utils");
function deprecated(funcId, message = "") {
  const { stack = "" } = Error("");
  log.warn(`${funcId} is deprecated, ${message}.`);
  otto.apm.sendCustomError("assets-legacy", {
    message: `${funcId}: ${message}`,
    stack,
    caller: stack?.split("\n")[2]
  });
}
function random() {
  return Math.random();
}
export {
  deprecated,
  log,
  random
};
