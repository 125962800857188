import { o_global } from "@gr-common/head/namespaces";
import { otto } from "./nexus.js";
const debugSync = o_global.debug ?? {};
const logger = debugSync.logger;
const scope = debugSync.scope;
const Level = debugSync.Level;
const status = debugSync.status;
const sendLog = (otto.apm ?? {}).sendCustomError;
export {
  Level,
  debugSync,
  logger,
  scope,
  sendLog,
  status
};
